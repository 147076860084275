.cta {
  color: $color-white;
  margin-left: auto;
  margin-right: auto;

  &__wrapper {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 135%;
    padding: 20px 20px 120px 20px;
    position: relative;
    width: 100%;
    z-index: 5;

    &--image {
      padding-bottom: 40px;
    }
  }

  &__background-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__close-btn {
    cursor: pointer;
    font-size: 1.2rem;
    margin: 10px 10px 0 auto;
    position: absolute;
    right: 0;
    z-index: 10;

    .icon-round {
      height: 30px;
      line-height: 30px;
      min-width: 30px;
    }
  }

  &__content {
    max-width: 552px;
  }

  &__primary-btn {
    margin-bottom: 20px;
    margin-right: 24px;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__image {
    img {
      clip-path: url("#curve");
      display: block;
      object-fit: cover;
      width: 100%;
    }
  }

  .link {
    white-space: nowrap;
  }

  &__form .cta__wrapper {
    background-position: left bottom;
  }

  .white-form {
    .FormConsentCheckbox {
      a {
        color: $color-dark;

        &:hover {
          color: $color-dark;
        }
      }
    }

    .EPiServerForms {
      a {
        border-color: $color-link-blue;
        color: $color-link-blue;

        &:hover {
          background-color: $color-link-blue;
          color: $color-white;
        }
      }
    }
  }

  .EPiServerForms {
    a {
      @extend .btn;
      @extend .btn--white;
    }

    .FormConsentCheckbox {
      margin-top: 10px;

      a {
        border: none;
        display: inline;
        font-size: 1.4rem;
        font-weight: normal;
        padding: 0;
        text-decoration: underline;

        &:hover {
          background-color: transparent;
          color: $color-white;
          text-decoration: none;
        }
      }
    }

    section {
      display: flex;
      flex-wrap: wrap;
    }

    .Form__Status {
      .Form__Status__Message {
        &.hide {
          display: none;
        }
      }
    }

    .Form__Element {
      margin-bottom: 10px;

      button {
        order: 1;

        @extend .btn;
        @extend .btn--cta;
      }

      input[type="checkbox"] {
        @extend .checkbox;
      }

      input[type="text"] {
        @extend .text-box;
      }

      .FormTextbox {
        flex-grow: 1;
        max-width: 500px;
        width: 100%;
      }

      .FormChoice,
      .FormConsentCheckbox {
        font-size: 1.4rem;
        width: 100%;

        fieldset {
          display: flex;
        }
      }

      .FormConsentCheckbox {
        p {
          display: inline;
        }
      }
    }
  }

  &__modal-dialog {
    margin-left: auto;
    margin-right: auto;
    max-height: 650px;
    overflow: auto;
    position: relative;
  }

  &__step-2 {
    align-items: center;
    background-color: $color-modal-bg;
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 30;

    &--open {
      display: flex;
    }
  }

  &__download {
    &-image {
      display: flex;
      justify-content: center;

      img {
        display: block;
        width: 200px;
      }
    }
  }

  &__desk-bg,
  &__m-bg {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  &__desk-bg {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .cta {
    &__wrapper {
      background-position: right;
      padding: 80px $column-width-d 49px;

      &--image {
        flex: 1;
        max-width: 640px;
        padding-right: 40px;
      }
    }

    &__text {
      margin-bottom: 40px;
    }

    &__background-wrapper {
      flex-direction: row;
    }

    .EPiServerForms {
      .Form__Element {
        button {
          align-self: baseline;
          order: initial;
        }

        .FormTextbox {
          margin-right: 24px;
        }
      }

      .FormConsentCheckbox {
        margin-top: 30px;
      }
    }

    &__step-2,
    &__form {
      .cta {
        &__wrapper {
          display: flex;
          justify-content: space-between;
        }

        &__content {
          max-width: 466px;
          padding-right: 10px;
        }

        &__modal-form,
        &__form-fields {
          width: 384px;
        }
      }
    }

    &__download {
      &-image {
        align-self: flex-end;
        padding-right: $column-width-d;
      }

      .cta__background-wrapper {
        flex-direction: row;
      }
    }

    &__desk-bg {
      display: block;
    }

    &__m-bg {
      display: none;
    }

    &__image {
      flex: 1;

      img {
        clip-path: url("#curve-desk");
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .cta {
    &__wrapper {
      background-position: right 40px;
      background-size: contain;
    }
  }
}

.cta-image-block {
  > div {
    display: flex;
    flex-wrap: wrap;

    > div {
      align-items: flex-end;
      display: flex;
      flex: 1 0 25%;
      justify-content: center;
      margin: 15px 0;

      @media only screen and (max-width: 768px) {
        flex: 1 0 100%;
      }
    }
  }

  &__image-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    padding: 10px;

    a {
      display: block;
      text-align: center;
      text-decoration: none;

      img {
        width: 100%;
      }
    }
  }
}
