.insight-page {
  &__title {
    margin-bottom: 20px;
  }

  &__primary {
    display: flex;
    justify-content: center;

    .card {
      border-bottom: none;
      margin-bottom: 30px;
      margin-right: 0;
      max-width: 500px;
      width: auto;

      &__link {
        padding-bottom: 0;
      }

      &__title {
        font-size: 2rem;
        -webkit-line-clamp: initial;
        line-clamp: initial;
      }

      &__type-label {
        left: 16px;
        top: 16px;
      }

      &__img {
        display: block;
        height: auto;
        max-width: 100%;

        &-wrap {
          max-width: 500px;
        }
      }
    }
  }

  &__secondary {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .insight-page {
    &__title {
      margin-bottom: 50px;
    }

    &__primary {
      display: block;

      .card {
        border-bottom: none;
        margin-bottom: 40px;
        margin-right: 0;
        max-width: 100%;
        width: 100%;

        &__type-label {
          left: 16px;
          top: 16px;
        }

        &__link {
          flex-direction: row;
          padding-bottom: 0;
          width: 100%;
        }

        &__desc {
          flex-grow: initial;
          flex-shrink: 2;
          justify-content: flex-end;
          min-width: 260px;
          padding: 0 0 40px 0;
          width: 100%;
        }

        &__title,
        &__read-time,
        &__infographic,
        &__card,
        &__video,
        &__webinar,
        &__event,
        &__link-text {
          padding: 0 40px;
        }

        &__title {
          flex-grow: initial;
          font-size: 2.4rem;
        }

        &__img {
          display: block;
          height: auto;
          max-width: 100%;
          width: auto;

          &-wrap {
            margin-bottom: 0;
            max-width: 744px;
          }
        }
      }
    }

    &__secondary {
      display: flex;
      justify-content: center;

      .card {
        margin-bottom: 40px;
        margin-right: 24px;
        max-width: 552px;
        width: auto;

        &:last-child {
          margin-right: 0;
        }

        &__type-label {
          left: 16px;
          top: 16px;
        }

        &__title {
          font-size: 2.4rem;
          -webkit-line-clamp: initial;
          line-clamp: initial;
        }

        &__desc {
          padding: 0 20px;
        }

        &__link {
          padding-bottom: 40px;
        }

        &__img {
          display: block;
          height: auto;
          max-width: 100%;
          width: auto;

          &-wrap {
            margin-bottom: 40px;
            max-width: 552px;
          }
        }
      }
    }
  }
}
