.related-block {
  padding-left: $container-padding-m;

  &__title {
    margin-bottom: 23px;
  }

  &__container {
    display: flex;

    &.tns-horizontal.tns-subpixel > {
      .card.tns-item {
        display: flex;
      }
    }
  }

  &__wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }

  &__cta {
    margin-top: 40px;
    text-align: center;
  }

  .card {
    border-bottom: none;
    margin-bottom: 0;
    margin-right: 16px;
    max-width: 248px;
    width: 248px;

    &__img {
      height: 170px;
      width: 248px;
    }

    &__img-wrap {
      max-width: 248px;
      width: 248px;
    }

    &__title,
    &__read-time,
    &__infographic,
    &__video,
    &__webinar,
    &__event,
    &__whitepaper,
    &__recwebinar,
    &__factsheet,
    &__link-text {
      padding: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .related-block {
    padding-left: $container-padding-d;

    &__title {
      margin-bottom: 38px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .related-block {
    padding-right: $container-padding-d;

    .card {
      margin-bottom: 0;
      margin-right: 24px;
      max-width: 360px;
      width: 360px;

      &__img {
        height: 225px;
        width: 360px;
      }

      &__img-wrap {
        max-width: 360px;
        width: 360px;
      }

      &__title,
      &__read-time,
      &__infographic,
      &__video,
      &__webinar,
      &__event,
      &__whitepaper,
      &__recwebinar,
      &__factsheet,
      &__link-text {
        padding: 0 20px;
      }
    }

    &--small {
      .card {
        max-width: 264px;
        width: 264px;

        &__img {
          height: 165px;
          width: 264px;
        }

        &__img-wrap {
          max-width: 264px;
          width: 264px;
        }
      }
    }
  }
}
