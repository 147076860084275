.vacancy-page {
  &__hero {
    margin-bottom: 36px;
  }

  .filters__panel {
    margin-bottom: 17px;
  }
}

@include media-breakpoint-up(md) {
  .vacancy-page {
    .filters__panel {
      margin-bottom: 60px;
    }
  }
}
