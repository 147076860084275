// Kainos colors
$color-dark: #252d4f;
$color-blue: #283583;
$color-white: #fff;
$color-black: #000;
$color-grey: #575756;
$color-grey-light: #f0f0ed;
$color-link-blue: #1b2db5;
$color-link-blue-hover: #edf3ff;
$color-green-light: #61a83f;
$color-disabled: #cdcdcd;
$color-green-form: #004631;
$color-modal-bg: rgba(37, 45, 79, 0.8);
$color-grey-medium: #70706f;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-grid-classes:         true !default;

// Prefix for :root CSS variables

$variable-prefix:             bs- !default;

$spacer: calc(min(4.44vw, 16px)) !default;
$spacers: (
  0: 0,
  1: calc(var(--spacer) / 4),
  2: calc(var(--spacer) / 2),
  3: calc(var(--spacer)),
  4: calc(var(--spacer) * 1.5),
  5: calc(var(--spacer) * 3),
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  md: 840px
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  md: 1200px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           calc(min(2vw, 24px)) !default;
$grid-row-columns:            6 !default;

$gutters: $spacers !default;

// Container padding
$container-padding-m : calc(min(3.34vw, 12px)) !default;
$container-padding-d : calc(min(3vw, 36px)) !default;
$column-width-d : calc(min(9vw, 96px)) !default;

$container-padding-x: $container-padding-d;
