/* Bootstrap functions */
@import "./node_modules/bootstrap/scss/functions";
@import "variables";

/* Bootstrap */

// Configuration
@import "./node_modules/bootstrap/scss/mixins/breakpoints";
@import "./node_modules/bootstrap/scss/mixins/container";
@import "./node_modules/bootstrap/scss/mixins/grid";

// Layout & components
@import "./node_modules/bootstrap/scss/containers";
@import "./node_modules/bootstrap/scss/grid";
@import "imports";
