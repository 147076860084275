.image-teaser-block {
  overflow: hidden;

  &--right {
    & .row {
      flex-direction: row-reverse;
    }
  }

  &--indent {
    margin-bottom: 20px;
  }

  &__impact {
    margin-bottom: 20px;
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-top: 20px;
  }

  &__link {
    border: 2px solid $color-link-blue;
    display: block;
    margin-bottom: 10px;
    padding: 25px 20px 28px 24px;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      color: $color-link-blue;
      font-weight: 500;
      margin-bottom: 23px;
    }

    &:hover {
      background-color: $color-link-blue;
      color: $color-white;

      .image-teaser-block__link-title {
        color: $color-white;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .image-teaser-block {
    &__content {
      display: block;
      padding-top: 0;
    }

    &__impact {
      margin-bottom: 40px;
    }

    &__pic-1 {
      width: 60%;
    }

    &__pic-2 {
      width: 80%;
    }

    .feature-mid-copy-block__image {
      &-wrapper {
        &--lg {
          margin-bottom: -90px;
        }
      }
    }

    &__link {
      margin-bottom: 20px;
      padding: 20px 56px 20px 24px;
    }

    &__img {
      margin-top: 68px;
    }
  }
}
