.search {
  &__bar {
    background-color: $color-grey-light;
    padding: 40px 0;

    .row {
      position: relative;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__input {
    border: 1px solid $color-grey;
    height: 40px;
    line-height: 16px;
    margin-right: 24px;
    padding: 0 12px;
    width: 100%;
  }

  &__btn {
    &-close {
      cursor: pointer;
      display: none;
      font-size: 12px;
      position: absolute;
      right: 0;
    }

    &-submit i {
      display: none;
    }
  }

  &__results {
    &-header {
      margin: 40px auto 80px;

      & .search__row {
        margin-bottom: -20px;
      }
    }

    &-number {
      display: inline-block;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    &-body {
      margin-bottom: 60px;

      & .search__results-item:not(:last-of-type) {
        border-bottom: 1px solid #dedede;
        margin-bottom: 25px;
        padding-bottom: 25px;
      }
    }

    &-item {
      &-title {
        color: $color-link-blue;
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        text-decoration: none;
      }

      &-tags {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-top: 15px;
        width: 100%;

        b {
          margin-bottom: 10px;
        }

        aside {
          display: flex;
          flex: 1 0 90%;
          flex-wrap: wrap;

          p {
            background: #eee;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            color: #313131;
            font-weight: 500;
            margin-bottom: 10px;
            margin-left: 10px;
            padding: 3px 18px;
          }
        }
      }

      &-description {
        margin-top: 10px;

        @extend .text--cut;
      }

      &-date {
        color: #989898;
        font-size: 13px;
        margin-top: 15px;
      }
    }

    &-footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      .search__results-number {
        margin-bottom: 0;
      }
    }
  }

  &__sort {
    margin-bottom: 20px;
    margin-left: auto;
    width: auto;

    &-dropdown {
      display: none;

      &--open {
        display: block;
      }
    }

    &-option--active {
      .icon-check {
        display: block;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .search {
    &__bar {
      background-color: $color-grey-light;
    }

    &__input {
      margin-right: 12px;
      min-width: auto;
      width: 100%;
    }

    &__btn {
      padding: 10px 16px;

      &-close {
        display: none;
        padding-right: 8px;
      }

      &-submit {
        & i {
          display: block;
          margin-left: 0;
        }
      }
    }
  }
}
