.country-banner {
  background-color: $color-grey-light;

  &--hidden {
    display: none;
  }

  &__container {
    padding: 38px 0 40px;
    position: relative;
  }

  & h5 {
    font-weight: 500;
    margin-bottom: 7px;
  }

  &__cross {
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 14px;
    top: calc(50% - 12px);
  }

  &__row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;

    & > * {
      margin-right: 12px;
      margin-top: 20px;
    }
  }

  &__decline {
    align-items: baseline;
    display: flex;
    flex-shrink: 0;
  }

  &__decline .link {
    color: $color-dark;
    display: block;
    font-weight: 400;
    margin-left: 4px;

    &::after {
      background-color: $color-dark;
    }
  }

  &__dropdown {
    background-color: $color-white;
    border: 1px solid $color-grey;
    border-top: none;
    cursor: pointer;
    display: none;
    list-style: none;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 20;

    &--open {
      display: block;
    }

    &-holder {
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      position: relative;
      width: 245px;
    }

    &-btn {
      align-items: center;
      background-color: $color-white;
      border: 1px solid $color-grey;
      cursor: pointer;
      display: flex;
      height: 40px;
      line-height: 1.6rem;
      overflow: hidden;
      padding: 0 12px;
      position: relative;
      width: 100%;
    }

    &-img {
      flex-shrink: 0;
      height: 20px;
      margin-right: 10px;
      width: 28px;
    }

    &-option {
      align-items: center;
      display: flex;
      height: 40px;
      line-height: 1.6rem;
      overflow: hidden;
      padding: 0 12px;

      &:hover {
        background-color: $color-grey-light;
      }
    }

    &-arrow {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}

@include media-breakpoint-down(md) {
  .country-banner {
    & h5 {
      font-size: 16px;
    }

    &__container {
      padding-top: 20px;
    }

    &__cross {
      top: 26px;
    }
  }
}
