@font-face {
  font-family: "kainos";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/kainos.woff") format("woff");
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  display: inline-block;
  /* stylelint-disable */
  font-family: "kainos";
  /* stylelint-enable */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  speak: never;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

//Custom icons
.icon-share {
  background-image: url("../../images/icons/Share.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 16px;
  width: 16px;
}

.icon-arrow {
  background-image: url("../../images/icons/Arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 13px;
  width: 7px;

  &--left {
    transform: rotate(180deg);
  }

  &--down {
    transform: rotate(90deg);
  }

  &--up {
    transform: rotate(-90deg);
  }

  &--filter {
    margin-bottom: 4px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.icon-audio {
  background-image: url("../../images/icons/audio.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 16px;
  width: 20px;
}

.icon-date {
  background-image: url("../../images/icons/date.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 19px;
  width: 18px;
}

.icon-infographic {
  background-image: url("../../images/icons/infographic.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-location {
  background-image: url("../../images/icons/location.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-time {
  background-image: url("../../images/icons/time.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-video {
  background-image: url("../../images/icons/video.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-webinar {
  background-image: url("../../images/icons/webinar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-factsheet {
  background-image: url("../../images/icons/factsheet.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-recwebinar {
  background-image: url("../../images/icons/recwebinar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-whitepaper {
  background-image: url("../../images/icons/whitepaper.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
}

.icon-menu {
  background-image: url("../../images/icons/menu.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 14px;
  width: 20px;
}

.icon-external {
  display: block;
  height: 14px;
  -webkit-mask-image: url("../../images/icons/external.svg");
  mask-image: url("../../images/icons/external.svg");
  width: 14px;
}

.icon-download {
  display: block;
  height: 16px;
  -webkit-mask-image: url("../../images/icons/download.svg");
  mask-image: url("../../images/icons/download.svg");
  width: 12px;
}

.icon-video-play {
  display: block;
  height: 19px;
  -webkit-mask-image: url("../../images/icons/play.svg");
  mask-image: url("../../images/icons/play.svg");
  width: 19px;
}

.icon-email {
  background-image: url("../../images/icons/mail.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 15px;
  width: 20px;
}

.icon-phone {
  background-image: url("../../images/icons/phone.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 20px;
  width: 20px;
}

.icon-check {
  width: 22px;
}

//Fontello icons

.icon-facebook::before { content: "\e802"; } /* '' */
.icon-link::before { content: "\e804"; } /* '' */
.icon-close::before { content: "\e805"; } /* '' */
.icon-check::before { content: "\e808"; } /* '' */
.icon-search::before { content: "\e809"; } /* '' */
.icon-twitter::before { content: "\f099"; } /* '' */
.icon-youtube::before { content: "\f16a"; } /* '' */
.icon-linkedin::before { content: "\f30c"; } /* '' */
