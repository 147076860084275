.v-card {
  margin-bottom: 40px;

  &__link {
    color: $color-link-blue;
    font-weight: 500;
    text-decoration: none;
  }

  &__props {
    display: flex;
    flex-wrap: wrap;
  }

  &__prop {
    color: $color-dark;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@include media-breakpoint-up(md) {
  .v-card {
    margin-bottom: 75px;
  }
}
