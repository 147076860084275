.mega-menu {
  background-color: $color-white;
  box-shadow: 0 15px 20px 0 rgba(37, 45, 79, 0.5);
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: space-between;
  overflow: hidden;
  transform: translate(375px);
  width: 335px;

  &__holder {
    height: 100vh;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    width: 0;

    &--open {
      width: 360px;
    }
  }

  a {
    @include disable-animation-v2();
  }

  &--open {
    transform: translate(25px);
    transition: transform 0.2s ease-out;
  }

  &__second-link {
    display: none;
  }

  &__primary-item {
    cursor: pointer;
    overflow-x: hidden;
    padding: 28px 28px 28px 24px;

    &--current {
      background-color: $color-grey-light;
    }

    &-label {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      background-color: $color-grey-light;

      .mega-menu__link.mega-menu__title {
        border-bottom: 2px solid;
      }
    }
  }

  &__link {
    display: inline-block;
    min-height: 2.6rem;
    text-decoration: none;
    white-space: nowrap;
  }

  &__back {
    align-items: center;
    display: flex;
    padding: 28px 28px 28px 24px;

    &-holder {
      background-color: $color-white;
      display: none;
    }

    .mega-menu__link {
      margin-left: 13px;
    }

    &:hover {
      .mega-menu__link {
        border-bottom: 2px solid;
      }
    }
  }

  &__secondary-right {
    padding: 18px 24px;
  }

  &__secondary-item {
    display: none;
  }

  &__secondary-column {
    margin-bottom: 40px;

    &-title {
      margin-bottom: 18px;
    }

    &-link {
      padding: 11px 0;
    }

    & .cta-block {
      @extend .link;

      color: $color-dark;
      font-weight: normal;
      transition: none;
      width: 100%;

      &::after {
        background-color: $color-white;
        transition: none;
      }

      &:hover {
        &::after {
          background: $color-dark;
          color: $color-dark;
          font-weight: 500;
          width: 100%;
        }
      }
    }
  }

  &__desc {
    margin-bottom: 15px;
  }

  &__mobile-title {
    font-size: 2.4rem;
    margin-bottom: 15px;
  }

  &__preview {
    background-color: $color-blue;
    color: $color-white;
    display: none;
    padding: 38px 24px;

    & .cta-block {
      @extend .link;

      color: $color-white;
      width: 100%;

      &::after {
        background-color: $color-white;
      }

      &:hover {
        &::after {
          background: $color-white;
          color: $color-white;
          width: 100%;
        }
      }
    }
  }

  &__button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 0 28px 0 24px;

    .header__get-in-touch,
    .header__country-selector {
      display: block;
    }

    .header__get-in-touch {
      margin-left: 0;
    }
  }

  &--country-selector-open {
    .mega-menu {
      &__back-holder {
        display: flex;
      }

      &__items,
      &__button {
        display: none;
      }
    }

    .country-selector {
      display: block;
    }
  }
}

@include media-breakpoint-down(md) {
  .mega-menu {
    position: relative;

    &__secondary-item {
      background-color: $color-white;
      box-shadow: 0 15px 20px 0 rgba(37, 45, 79, 0.5);
      display: flex;
      flex-flow: column;
      height: 100%;
      overflow-y: hidden;
      position: absolute;
      right: -365px;
      top: 0;
      transition: right 0.2s ease-out;
      width: 335px;

      .mega-menu {
        &__back-holder,
        &__preview {
          display: block;
        }
      }
    }

    &__secondary-right {
      overflow-y: auto;
    }

    &--open-sub-menu {
      .mega-menu__primary-item--active {
        display: block;

        &:hover {
          background-color: $color-white;
        }

        .mega-menu__secondary-item {
          right: 0;
          transition: right 0.2s ease-out;
        }

        .mega-menu__link--second {
          display: inline-block;
        }
      }
    }
  }
}

// Custom breakpoints for small desktops
@media only screen and (min-width: 840px) and (max-width: 996px) {
  .header {
    &__logo-wrap {
      margin-right: 3px;
    }

    .container {
      padding-left: 10px;
      padding-right: 10px;
    }

    .mega-menu {
      &__primary-item {
        padding: 0 3px !important;

        &-label {
          padding: 8px 2px;
        }
      }
    }

    .modal-social-share,
    .mega-menu__search,
    .mega-menu__menu,
    .mega-menu__get-in-touch,
    .mega-menu__country-selector {
      margin-left: 7px;
    }
  }
}

@include media-breakpoint-up(md) {
  .mega-menu {
    box-shadow: none;
    display: flex;
    flex-direction: initial;
    height: auto;
    justify-content: initial;
    overflow: initial;
    position: initial;
    transform: none;
    width: auto;

    &__holder {
      display: flex;
      height: auto;
      overflow: initial;
      position: initial;
      width: auto;
    }

    &__title {
      align-items: center;
      border-bottom: none;
      display: flex;
      outline-offset: 12px;
      padding: 0;
      position: relative;

      &::after {
        background-color: $color-blue;
        content: "";
        height: 2px;
        opacity: 0;
        position: absolute;
        top: 23px;
        width: 100%;
      }
    }

    &__items {
      display: flex;
      margin-bottom: 0;
    }

    &__preview {
      display: block;
      max-width: 360px;
      padding: 40px 40px 72px 40px;
    }

    &__desc {
      margin-bottom: 40px;
    }

    &__secondary-item {
      background-color: $color-white;
      box-shadow: 0 4px 20px 0 rgba(37, 45, 79, 0.4);
      left: 0;
      margin: 0 auto;
      max-height: 500px;
      max-width: 1128px;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 100%;
    }

    &__secondary-right {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      padding: 36px 59px 40px;

      &-wrap {
        padding: 0 5px;
      }
    }

    &__secondary-column {
      margin-bottom: 0;

      & .cta-block {
        &:hover {
          font-weight: normal;
        }
      }
    }

    &--open-sub-menu {
      .mega-menu__primary-item--active {
        background-color: $color-white;

        .mega-menu__secondary-item {
          display: flex;
        }
      }
    }

    &__primary-item {
      align-items: center;
      display: flex;
      height: 80px;
      padding: 0 6px;

      &-label {
        padding: 7px 12px 7px;
      }

      &--current {
        background-color: $color-white;

        .mega-menu__primary-item-label {
          background-color: $color-blue;
          color: $color-white;
        }

        &:hover {
          .mega-menu__link.mega-menu__title {
            border-bottom: none;
          }
        }
      }

      &:hover {
        background-color: $color-white;

        .mega-menu__secondary-item {
          display: flex;
        }

        .mega-menu__link.mega-menu__title {
          border-bottom: none;

          &::after {
            opacity: 1;
          }
        }
      }

      .icon-arrow {
        display: none;
      }
    }

    &__back-holder {
      display: none;
    }

    &__button {
      margin-bottom: 0;
      padding: 0;
    }
  }
}
