.quote-block {
  overflow: hidden;

  &__info {
    box-sizing: content-box;
    width: calc(min(46vw, 552px));
    z-index: 1;
  }

  &__outer {
    background-color: $color-white;
    border: 1px solid $color-grey-light;
  }

  &__inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(min(46vw, 552px));
    padding: calc(min(2vw, 24px)) calc(min(6vw, 72px));
  }

  &__quote {
    margin-bottom: 20px;

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .quote-block {
    &__row {
      align-items: center;
    }

    &__info {
      margin-right: -50%;
    }
  }
}

@include media-breakpoint-down(md) {
  .quote-block {
    &__info {
      margin: auto;
      order: 1;
      width: 93.32vw;
    }

    &__outer {
      margin: -20px 10px 0;
    }

    &__inner {
      min-height: calc(93.32vw - 20px);
      padding: 6vw;
    }

    &__quote {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
