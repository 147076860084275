.testimonial-block {
  &__inner {
    margin-bottom: 20px;
  }

  &__data {
    padding-bottom: 24px;
    padding-top: 40px;
    position: relative;

    &::before,
    &::after {
      border-top: 4px solid $color-green-light;
      content: "";
      position: absolute;
      transition: width 1s 0.25s;
      width: 0;
    }

    &--filled {
      &::before,
      &::after {
        transition: width 1s 0.25s;
        width: 50%;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
      right: 8px;
    }
  }

  &__controls {
    display: none;
  }

  &__quote {
    margin-bottom: 20px;
  }

  &__person {
    &-full-name {
      font-weight: 500;
    }

    &-company-logo {
      background-repeat: no-repeat;
      background-size: contain;
      height: 60px;
      margin-top: 20px;
    }
  }
}

.tns-nav {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    background-color: $color-grey-light;
    border: none;
    border-radius: 4px;
    display: block;
    height: 8px;
    margin: 0 4px;
    width: 8px;

    &.tns-nav-active {
      background-color: $color-blue;
    }
  }
}

@include media-breakpoint-up(md) {
  .testimonial-block {
    &__inner {
      margin-bottom: 40px;
      width: 80%;
    }

    &__data {
      padding: 84px 0 44px;

      &::after {
        right: 0;
      }
    }

    &__controls {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: 1;

      &:focus {
        outline: none;
      }

      li {
        .icon-round {
          position: relative;
        }

        i {
          filter: invert(15%) sepia(81%) saturate(7493%) hue-rotate(240deg) brightness(72%) contrast(95%); // $color-link-blue
          left: calc(50% - 4px);
          position: absolute;
          top: calc(50% - 7px);
        }

        &:hover {
          cursor: pointer;
        }

        &[aria-disabled=true] .icon-round {
          display: none;
        }
      }
    }
  }
}
