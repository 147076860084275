.page-details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: -10px;
    margin-top: 20px;

    &:only-child() {
      flex-basis: auto;
      flex-direction: row;
    }

    & > div {
      margin-bottom: 10px;
      margin-right: 12px;
    }

    & span {
      white-space: nowrap;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;

    .page-details__col {
      margin-top: 0;
    }
  }

  &__title {
    font-weight: 600;
    white-space: nowrap;
  }

  &__reading-time {
    align-items: center;
    display: flex;

    & i {
      background-size: contain;
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }
  }

  &__author {
    &-image {
      background-position: center center;
      background-size: cover;
      border-radius: 36px;
      flex-shrink: 0;
      height: 72px;
      margin-right: 16px;
      width: 72px;

      &--big {
        border-radius: 84px;
        height: 168px;
        width: 168px;
      }
    }

    &-full-name {
      white-space: normal;
    }

    &-bio {
      margin-top: 40px;
    }

    &-info {
      &__title {
        margin-bottom: 20px;
      }
    }

    &-links {
      color: $color-link-blue;
      display: flex;
      font-size: 1.8rem;
      position: static;

      .icon-round {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }

    &--large {
      & .page-details__author-job {
        margin-bottom: 0 !important;
      }
    }
  }

  &__location {
    align-items: center;
    display: flex;

    i {
      margin-right: 8px;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-details {
    &__col {
      flex-basis: 50%;
      margin-right: -12px;
    }

    &__author {
      flex-basis: auto;

      &--large {
        align-items: flex-start;
        flex-direction: column;

        & .page-details__author {
          &-image {
            margin-bottom: 20px;
          }

          &-links {
            margin-top: 20px;
          }
        }
      }

      &-bio {
        margin-top: 20px;
      }

      .page-details__col {
        flex-basis: auto;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .page-details {
    flex-wrap: nowrap;

    & > div:not(:last-of-type) {
      margin-right: calc(10% - 12px);
    }

    &__col {
      // contains 2 children, apply styles to all
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ & {
        align-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    &__author {
      &-image {
        margin-right: 24px;
      }

      &-links {
        position: absolute;
        top: calc(100% + 15px);
      }

      &-info {
        &__title {
          margin-bottom: 40px;
        }
      }

      & .page-details__col {
        position: relative;
      }
    }
  }
}
