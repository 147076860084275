@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $color-white;
    border: 1px solid $color-dark;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 10px 0 0;
    position: relative;
    vertical-align: top;
    width: 20px;

    &::after {
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;
    }

    &:checked {
      background-color: $color-blue;
      border-color: $color-blue;

      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:disabled {
      background-color: $color-white;
      cursor: not-allowed;
      opacity: 0.4;

      & + label {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          // oode
        }
      }
    }
  }

  input.checkbox {
    &::after {
      border: 2px solid $color-white;
      border-left: 0;
      border-top: 0;
      height: 11px;
      left: 6px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
      width: 7px;
    }

    &:checked {
      --r: 43deg;
    }
  }
}

.text-box {
  background-color: $color-white;
  border: 1px solid $color-grey;
  height: 40px;
  padding: 0 12px;
  width: 100%;

  &::placeholder {
    color: $color-grey-medium;
    padding-left: 0;
  }
}

textarea {
  background-color: $color-white;
  border: 1px solid $color-grey;
  min-height: 100px;
  padding: 5px 12px;
  width: 100%;

  &::placeholder {
    color: $color-grey-medium;
    padding-left: 0;
  }
}

//Episerver forms
.green-form {
  background: linear-gradient(90deg, #004631 0%, #61a83f 100%);

  h2 {
    color: $color-white;
  }

  .EPiServerForms {
    .Form__Element {
      button {
        @extend .btn--white;

        &:hover {
          color: $color-green-form !important;
        }
      }
    }
  }

  .btn {
    &--white-bg {
      color: $color-green-form;

      &:hover {
        &.btn i.icon-download,
        i {
          background-color: $color-white;
        }
      }
    }

    & i.icon-download,
    i {
      background-color: $color-green-form;
    }
  }

  .link {
    &::before {
      background-color: $color-green-form;
    }
  }
}

.blue-form {
  background: linear-gradient(270deg, #41679f 0%, #283583 100%);

  h2 {
    color: $color-white;
  }

  .cta__primary-btn {
    color: $color-link-blue;
  }

  .btn {
    &--white-bg {
      color: $color-link-blue;

      &:hover {
        &.btn i.icon-download,
        i {
          background-color: $color-white;
        }
      }
    }

    & i.icon-download,
    i {
      background-color: $color-link-blue;
    }
  }

  .EPiServerForms {
    .Form__Element {
      button {
        @extend .btn--white;
      }
    }
  }

  .link {
    &::before {
      background-color: $color-link-blue;
    }
  }
}

.white-form {
  background: $color-white;
  border: 2px solid $color-grey-light;
  color: $color-dark;

  h2 {
    color: $color-dark;
  }

  .btn--white-bg {
    background-color: $color-link-blue;
    border-color: $color-link-blue;
    color: $color-white;

    &.btn i.icon-download,
    i {
      background-color: $color-white;
    }

    &:hover {
      border-color: $color-link-blue;
      color: $color-link-blue;

      &.btn i.icon-download,
      i {
        background-color: $color-link-blue;
      }
    }
  }

  .link--white {
    color: $color-link-blue;

    &::after {
      background-color: $color-link-blue;
    }
  }

  .FormConsentCheckbox {
    a {
      color: $color-dark;

      &:hover {
        color: $color-dark;
      }
    }
  }
}

.EPiServerForms {
  .Form__Element {
    .Form__Element__ValidationError {
      font-size: 1.4rem;
    }
  }
}
