.service-teaser {
  &__item {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    &-image {
      width: 100%;
    }

    &-content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
    }

    &-link {
      display: inline;

      &::before,
      &::after {
        bottom: -4px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .service-teaser {
    &__item {
      height: 100%;

      &:hover {
        background-color: $color-link-blue-hover;

        .link {
          @include link-animation-v2();
        }
      }

      &-content {
        height: 100%;
        padding: 40px;
      }

      &-badge {
        margin-bottom: 16px;
      }

      &-title {
        font-size: 3.2rem;
        line-height: 3.8rem;
        margin-bottom: 40px;
      }

      &-link {
        margin-top: auto;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .service-teaser {
    & .row {
      margin-bottom: -20px;
    }

    &__item {
      margin-bottom: 20px;

      &-content {
        padding: 20px 0;
      }

      &-badge {
        margin-bottom: 20px;
      }

      &-title {
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-bottom: 20px;
      }
    }
  }
}
