@charset "UTF-8";
/* Bootstrap functions */
/* Bootstrap */
.container,
.container-fluid,
.container-md {
  width: 100%;
  padding-right: var(--bs-gutter-x, calc(min(3vw, 36px)));
  padding-left: var(--bs-gutter-x, calc(min(3vw, 36px)));
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 840px) {
  .container, .container-md {
    max-width: 1200px; } }

.row {
  --bs-gutter-x: calc(min(2vw, 24px));
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: calc(var(--spacer) / 4); }

.g-1,
.gy-1 {
  --bs-gutter-y: calc(var(--spacer) / 4); }

.g-2,
.gx-2 {
  --bs-gutter-x: calc(var(--spacer) / 2); }

.g-2,
.gy-2 {
  --bs-gutter-y: calc(var(--spacer) / 2); }

.g-3,
.gx-3 {
  --bs-gutter-x: calc(var(--spacer)); }

.g-3,
.gy-3 {
  --bs-gutter-y: calc(var(--spacer)); }

.g-4,
.gx-4 {
  --bs-gutter-x: calc(var(--spacer) * 1.5); }

.g-4,
.gy-4 {
  --bs-gutter-y: calc(var(--spacer) * 1.5); }

.g-5,
.gx-5 {
  --bs-gutter-x: calc(var(--spacer) * 3); }

.g-5,
.gy-5 {
  --bs-gutter-y: calc(var(--spacer) * 3); }

@media (min-width: 840px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2, .awards-strip-block__col {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: calc(var(--spacer) / 4); }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: calc(var(--spacer) / 4); }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: calc(var(--spacer) / 2); }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: calc(var(--spacer) / 2); }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: calc(var(--spacer)); }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: calc(var(--spacer)); }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: calc(var(--spacer) * 1.5); }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: calc(var(--spacer) * 1.5); }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: calc(var(--spacer) * 3); }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: calc(var(--spacer) * 3); } }

@-ms-viewport {
  width: device-width; }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

*, ::after, ::before {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  line-height: 1;
  font-family: sans-serif;
  text-align: left; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::after, blockquote::before, q::after, q::before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption {
  caption-side: bottom; }

th {
  text-align: inherit; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

code, kbd, pre, samp {
  font-family: monospace,monospace; }

pre {
  overflow: auto;
  -ms-overflow-style: scrollbar; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

fieldset {
  min-width: 0; }

legend {
  max-width: 100%;
  white-space: normal;
  color: inherit;
  display: block; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

output {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

@font-face {
  font-family: "kainos";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/kainos.woff") format("woff"); }

[class^="icon-"]::before,
[class*=" icon-"]::before {
  display: inline-block;
  /* stylelint-disable */
  font-family: "kainos";
  /* stylelint-enable */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  speak: never;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em; }

.icon-share {
  background-image: url("../../images/icons/Share.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 16px;
  width: 16px; }

.icon-arrow {
  background-image: url("../../images/icons/Arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 13px;
  width: 7px; }
  .icon-arrow--left {
    transform: rotate(180deg); }
  .icon-arrow--down {
    transform: rotate(90deg); }
  .icon-arrow--up {
    transform: rotate(-90deg); }
  .icon-arrow--filter {
    margin-bottom: 4px; }
    @media (min-width: 840px) {
      .icon-arrow--filter {
        margin-bottom: 0; } }

.icon-audio {
  background-image: url("../../images/icons/audio.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 16px;
  width: 20px; }

.icon-date {
  background-image: url("../../images/icons/date.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 19px;
  width: 18px; }

.icon-infographic {
  background-image: url("../../images/icons/infographic.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-location {
  background-image: url("../../images/icons/location.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-time {
  background-image: url("../../images/icons/time.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-video {
  background-image: url("../../images/icons/video.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-webinar {
  background-image: url("../../images/icons/webinar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-factsheet {
  background-image: url("../../images/icons/factsheet.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-recwebinar {
  background-image: url("../../images/icons/recwebinar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-whitepaper {
  background-image: url("../../images/icons/whitepaper.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px; }

.icon-menu {
  background-image: url("../../images/icons/menu.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 14px;
  width: 20px; }

.icon-external {
  display: block;
  height: 14px;
  -webkit-mask-image: url("../../images/icons/external.svg");
  mask-image: url("../../images/icons/external.svg");
  width: 14px; }

.icon-download {
  display: block;
  height: 16px;
  -webkit-mask-image: url("../../images/icons/download.svg");
  mask-image: url("../../images/icons/download.svg");
  width: 12px; }

.icon-video-play {
  display: block;
  height: 19px;
  -webkit-mask-image: url("../../images/icons/play.svg");
  mask-image: url("../../images/icons/play.svg");
  width: 19px; }

.icon-email {
  background-image: url("../../images/icons/mail.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 15px;
  width: 20px; }

.icon-phone {
  background-image: url("../../images/icons/phone.svg");
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 20px;
  width: 20px; }

.icon-check {
  width: 22px; }

.icon-facebook::before {
  content: "\e802"; }

/* '' */
.icon-link::before {
  content: "\e804"; }

/* '' */
.icon-close::before {
  content: "\e805"; }

/* '' */
.icon-check::before {
  content: "\e808"; }

/* '' */
.icon-search::before {
  content: "\e809"; }

/* '' */
.icon-twitter::before {
  content: "\f099"; }

/* '' */
.icon-youtube::before {
  content: "\f16a"; }

/* '' */
.icon-linkedin::before {
  content: "\f30c"; }

/* '' */
/* stylelint-disable */
@keyframes slidein {
  0% {
    left: -10px; }
  1% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    left: 100%;
    opacity: 0; } }

/* stylelint-enable */
/*
TO DO Delete unusable fonts
 */
/*
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 100;
  src: url(../../fonts/Galano-Grotesque-Thin.otf) format("opentype");
}

//Extra Light
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 200;
  src: url(../../fonts/Galano-Grotesque-ExtraLight.otf) format("opentype");
}

*/
@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 300;
  src: url(../../fonts/Galano-Grotesque-Light.otf) format("opentype"); }

@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Galano-Grotesque.otf) format("opentype"); }

@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/Galano-Grotesque-Medium.otf) format("opentype"); }

@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 600;
  src: url(../../fonts/Galano-Grotesque-SemiBold.otf) format("opentype"); }

@font-face {
  font-family: "Galano Grotesque";
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Galano-Grotesque-Bold.otf) format("opentype"); }

/*
//Extra Bold
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 800;
  src: url(../../fonts/Galano-Grotesque-ExtraBold.otf) format("opentype");
}

//Heavy
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 900;
  src: url(../../fonts/Galano-Grotesque-Heavy.otf) format("opentype");
}

*/
@font-face {
  font-family: "Galano Grotesque";
  font-style: italic;
  font-weight: 400;
  src: url(../../fonts/Galano-Grotesque-Italic.otf) format("opentype"); }

/*
//Thin
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 100;
  src: url(../../fonts/Galano-Grotesque-Thin-Italic.otf) format("opentype");
}

//Extra Light
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 200;
  src: url(../../fonts/Galano-Grotesque-ExtraLight-Italic.otf) format("opentype");
}

//Light
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 300;
  src: url(../../fonts/Galano-Grotesque-Light-Italic.otf) format("opentype");
}

//Medium
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 500;
  src: url(../../fonts/Galano-Grotesque-Medium-Italic.otf) format("opentype");
}

//Semi Bold
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 600;
  src: url(../../fonts/Galano-Grotesque-SemiBold-Italic.otf) format("opentype");
}

//Bold
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 700;
  src: url(../../fonts/Galano-Grotesque-Bold-Italic.otf) format("opentype");
}

//Extra Bold
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 800;
  src: url(../../fonts/Galano-Grotesque-ExtraBold-Italic.otf) format("opentype");
}

//Heavy
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 900;
  src: url(../../fonts/Galano-Grotesque-Heavy-Italic.otf) format("opentype");
}
*/
@font-face {
  font-family: "Foundry Gridnik";
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Foundry-Gridnik-Regular.otf) format("opentype"); }

@font-face {
  font-family: "Foundry Gridnik";
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Foundry-Gridnik-Bold.otf) format("opentype"); }

.typography a {
  color: #1b2db5; }
  .typography a:hover {
    text-decoration: none; }

.typography em {
  font-style: italic; }

.typography p {
  margin-bottom: 20px; }

.typography ul,
.typography ol {
  margin: 20px 0 20px 30px; }

.typography ul {
  list-style: disc; }

.typography ol {
  list-style: decimal; }

.typography li:not(:last-of-type) {
  margin-bottom: 20px; }

.typography > :last-child {
  margin-bottom: 0; }

.typography > :first-child {
  margin-top: 0; }

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5 {
  margin: 20px 0; }

@media (min-width: 840px) {
  .typography ul,
  .typography ol {
    margin: 40px 0 40px 30px; } }

html,
body {
  color: #252d4f;
  font-family: "Galano Grotesque";
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  min-width: 320px; }

html {
  font-size: 10px; }

.js-focus-visible :focus:not(.focus-visible) {
  outline: none; }

a,
button {
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background: transparent;
  border: none;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  width: auto;
  /* Remove excess padding and border in Firefox 4+ */ }
  a::-moz-focus-inner,
  button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  a:focus, a:hover,
  button:focus,
  button:hover {
    cursor: pointer; }

a {
  font-size: 1.6rem; }

a,
li[tabindex],
button[type="submit"] {
  outline-color: black;
  outline-offset: 7px;
  outline-width: 2px; }
  a:focus,
  li[tabindex]:focus,
  button[type="submit"]:focus {
    outline-color: black;
    outline-style: solid; }
  a.btn, .hero a.cta-block, .cta .EPiServerForms a, a.pagination-btn,
  li[tabindex].btn,
  .hero li.cta-block[tabindex],
  li[tabindex].pagination-btn,
  button[type="submit"].btn,
  .hero button.cta-block[type="submit"],
  .newsletter .EPiServerForms .Form__Element button[type="submit"],
  .cta .EPiServerForms .Form__Element button[type="submit"],
  button[type="submit"].pagination-btn {
    outline-offset: 0; }

input[type="checkbox"] {
  outline-offset: 0; }

input,
button {
  outline-color: black;
  outline-offset: 0; }
  input:focus,
  button:focus {
    outline-color: black;
    outline-style: solid;
    outline-width: medium; }

.d-flex {
  display: flex; }

.flex-w {
  flex-wrap: wrap; }

.hidden {
  display: none; }

.overflow-hidden {
  overflow: hidden; }

.center {
  text-align: center; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.v-center {
  justify-content: center; }

.v-left {
  justify-content: flex-start; }

.v-right {
  justify-content: flex-end; }

.align-self-center {
  align-self: center; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.defaultcase {
  text-transform: none !important; }

.no-border {
  border: 0 none !important; }

.bold {
  font-weight: 500; }

.indent-10 {
  margin-bottom: 10px; }

.indent-20 {
  margin-bottom: 20px; }

.indent-24 {
  margin-bottom: 24px; }

.indent-40 {
  margin-bottom: 40px; }

.indent-53 {
  margin-bottom: 53px; }

.svg {
  height: 0;
  position: absolute;
  width: 0; }

.small,
.content-wrapper {
  margin: 40px 0; }

.medium {
  margin: 80px 0; }

.large {
  margin: 120px 0; }

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

@media (max-width: 839.98px) {
  .container {
    padding-left: calc(min(3.34vw, 12px));
    padding-right: calc(min(3.34vw, 12px)); }
  .row {
    margin-left: -8px;
    margin-right: -8px; }
    .row > * {
      padding-left: 8px;
      padding-right: 8px; }
  .small,
  .content-wrapper {
    margin: 20px 0; }
  .medium {
    margin: 40px 0; }
  .large {
    margin: 80px 0; } }

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .checkbox, .newsletter .EPiServerForms .Form__Element input[type="checkbox"], .cta .EPiServerForms .Form__Element input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: 1px solid #252d4f;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 10px 0 0;
    position: relative;
    vertical-align: top;
    width: 20px; }
    .checkbox::after, .newsletter .EPiServerForms .Form__Element input[type="checkbox"]::after, .cta .EPiServerForms .Form__Element input[type="checkbox"]::after {
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0; }
    .checkbox:checked, .newsletter .EPiServerForms .Form__Element input:checked[type="checkbox"], .cta .EPiServerForms .Form__Element input:checked[type="checkbox"] {
      background-color: #283583;
      border-color: #283583;
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2); }
    .checkbox:disabled, .newsletter .EPiServerForms .Form__Element input:disabled[type="checkbox"], .cta .EPiServerForms .Form__Element input:disabled[type="checkbox"] {
      background-color: #fff;
      cursor: not-allowed;
      opacity: 0.4; }
      .checkbox:disabled + label, .newsletter .EPiServerForms .Form__Element input:disabled[type="checkbox"] + label, .cta .EPiServerForms .Form__Element input:disabled[type="checkbox"] + label {
        cursor: not-allowed;
        opacity: 0.4; }
  input.checkbox::after, .newsletter .EPiServerForms .Form__Element input[type="checkbox"]::after, .cta .EPiServerForms .Form__Element input[type="checkbox"]::after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 11px;
    left: 6px;
    top: 2px;
    transform: rotate(var(--r, 20deg));
    width: 7px; }
  input.checkbox:checked, .newsletter .EPiServerForms .Form__Element input:checked[type="checkbox"], .cta .EPiServerForms .Form__Element input:checked[type="checkbox"] {
    --r: 43deg; } }

.text-box, .newsletter .EPiServerForms .Form__Element input[type="text"], .cta .EPiServerForms .Form__Element input[type="text"] {
  background-color: #fff;
  border: 1px solid #575756;
  height: 40px;
  padding: 0 12px;
  width: 100%; }
  .text-box::placeholder, .newsletter .EPiServerForms .Form__Element input[type="text"]::placeholder, .cta .EPiServerForms .Form__Element input[type="text"]::placeholder {
    color: #70706f;
    padding-left: 0; }

textarea {
  background-color: #fff;
  border: 1px solid #575756;
  min-height: 100px;
  padding: 5px 12px;
  width: 100%; }
  textarea::placeholder {
    color: #70706f;
    padding-left: 0; }

.green-form {
  background: linear-gradient(90deg, #004631 0%, #61a83f 100%); }
  .green-form h2 {
    color: #fff; }
  .green-form .EPiServerForms .Form__Element button:hover {
    color: #004631 !important; }
  .green-form .btn--white-bg {
    color: #004631; }
    .green-form .btn--white-bg:hover.btn i.icon-download, .green-form .hero .btn--white-bg.cta-block:hover i.icon-download, .hero .green-form .btn--white-bg.cta-block:hover i.icon-download, .green-form .newsletter .EPiServerForms .Form__Element button.btn--white-bg:hover i.icon-download, .newsletter .EPiServerForms .Form__Element .green-form button.btn--white-bg:hover i.icon-download, .green-form .cta .EPiServerForms a.btn--white-bg:hover i.icon-download, .cta .EPiServerForms .green-form a.btn--white-bg:hover i.icon-download, .green-form .cta .EPiServerForms .Form__Element button.btn--white-bg:hover i.icon-download, .cta .EPiServerForms .Form__Element .green-form button.btn--white-bg:hover i.icon-download,
    .green-form .btn--white-bg:hover i {
      background-color: #fff; }
  .green-form .btn i.icon-download, .green-form .hero .cta-block i.icon-download, .hero .green-form .cta-block i.icon-download, .green-form .newsletter .EPiServerForms .Form__Element button i.icon-download, .newsletter .EPiServerForms .Form__Element .green-form button i.icon-download, .green-form .cta .EPiServerForms a i.icon-download, .cta .EPiServerForms .green-form a i.icon-download, .green-form .cta .EPiServerForms .Form__Element button i.icon-download, .cta .EPiServerForms .Form__Element .green-form button i.icon-download,
  .green-form .btn i,
  .green-form .hero .cta-block i,
  .hero .green-form .cta-block i,
  .green-form .newsletter .EPiServerForms .Form__Element button i,
  .newsletter .EPiServerForms .Form__Element .green-form button i,
  .green-form .cta .EPiServerForms a i,
  .cta .EPiServerForms .green-form a i,
  .green-form .cta .EPiServerForms .Form__Element button i,
  .cta .EPiServerForms .Form__Element .green-form button i {
    background-color: #004631; }
  .green-form .link::before, .green-form .mega-menu__secondary-column .cta-block::before, .mega-menu__secondary-column .green-form .cta-block::before, .green-form .mega-menu__preview .cta-block::before, .mega-menu__preview .green-form .cta-block::before, .green-form .feature-mid-copy-block .cta-block::before, .feature-mid-copy-block .green-form .cta-block::before, .green-form .feature-image-text-heading-cta-block .cta-block::before, .feature-image-text-heading-cta-block .green-form .cta-block::before {
    background-color: #004631; }

.blue-form {
  background: linear-gradient(270deg, #41679f 0%, #283583 100%); }
  .blue-form h2 {
    color: #fff; }
  .blue-form .cta__primary-btn {
    color: #1b2db5; }
  .blue-form .btn--white-bg {
    color: #1b2db5; }
    .blue-form .btn--white-bg:hover.btn i.icon-download, .blue-form .hero .btn--white-bg.cta-block:hover i.icon-download, .hero .blue-form .btn--white-bg.cta-block:hover i.icon-download, .blue-form .newsletter .EPiServerForms .Form__Element button.btn--white-bg:hover i.icon-download, .newsletter .EPiServerForms .Form__Element .blue-form button.btn--white-bg:hover i.icon-download, .blue-form .cta .EPiServerForms a.btn--white-bg:hover i.icon-download, .cta .EPiServerForms .blue-form a.btn--white-bg:hover i.icon-download, .blue-form .cta .EPiServerForms .Form__Element button.btn--white-bg:hover i.icon-download, .cta .EPiServerForms .Form__Element .blue-form button.btn--white-bg:hover i.icon-download,
    .blue-form .btn--white-bg:hover i {
      background-color: #fff; }
  .blue-form .btn i.icon-download, .blue-form .hero .cta-block i.icon-download, .hero .blue-form .cta-block i.icon-download, .blue-form .newsletter .EPiServerForms .Form__Element button i.icon-download, .newsletter .EPiServerForms .Form__Element .blue-form button i.icon-download, .blue-form .cta .EPiServerForms a i.icon-download, .cta .EPiServerForms .blue-form a i.icon-download, .blue-form .cta .EPiServerForms .Form__Element button i.icon-download, .cta .EPiServerForms .Form__Element .blue-form button i.icon-download,
  .blue-form .btn i,
  .blue-form .hero .cta-block i,
  .hero .blue-form .cta-block i,
  .blue-form .newsletter .EPiServerForms .Form__Element button i,
  .newsletter .EPiServerForms .Form__Element .blue-form button i,
  .blue-form .cta .EPiServerForms a i,
  .cta .EPiServerForms .blue-form a i,
  .blue-form .cta .EPiServerForms .Form__Element button i,
  .cta .EPiServerForms .Form__Element .blue-form button i {
    background-color: #1b2db5; }
  .blue-form .link::before, .blue-form .mega-menu__secondary-column .cta-block::before, .mega-menu__secondary-column .blue-form .cta-block::before, .blue-form .mega-menu__preview .cta-block::before, .mega-menu__preview .blue-form .cta-block::before, .blue-form .feature-mid-copy-block .cta-block::before, .feature-mid-copy-block .blue-form .cta-block::before, .blue-form .feature-image-text-heading-cta-block .cta-block::before, .feature-image-text-heading-cta-block .blue-form .cta-block::before {
    background-color: #1b2db5; }

.white-form {
  background: #fff;
  border: 2px solid #f0f0ed;
  color: #252d4f; }
  .white-form h2 {
    color: #252d4f; }
  .white-form .btn--white-bg {
    background-color: #1b2db5;
    border-color: #1b2db5;
    color: #fff; }
    .white-form .btn--white-bg.btn i.icon-download, .white-form .hero .btn--white-bg.cta-block i.icon-download, .hero .white-form .btn--white-bg.cta-block i.icon-download, .white-form .newsletter .EPiServerForms .Form__Element button.btn--white-bg i.icon-download, .newsletter .EPiServerForms .Form__Element .white-form button.btn--white-bg i.icon-download, .white-form .cta .EPiServerForms a.btn--white-bg i.icon-download, .cta .EPiServerForms .white-form a.btn--white-bg i.icon-download, .white-form .cta .EPiServerForms .Form__Element button.btn--white-bg i.icon-download, .cta .EPiServerForms .Form__Element .white-form button.btn--white-bg i.icon-download,
    .white-form .btn--white-bg i {
      background-color: #fff; }
    .white-form .btn--white-bg:hover {
      border-color: #1b2db5;
      color: #1b2db5; }
      .white-form .btn--white-bg:hover.btn i.icon-download, .white-form .hero .btn--white-bg.cta-block:hover i.icon-download, .hero .white-form .btn--white-bg.cta-block:hover i.icon-download, .white-form .newsletter .EPiServerForms .Form__Element button.btn--white-bg:hover i.icon-download, .newsletter .EPiServerForms .Form__Element .white-form button.btn--white-bg:hover i.icon-download, .white-form .cta .EPiServerForms a.btn--white-bg:hover i.icon-download, .cta .EPiServerForms .white-form a.btn--white-bg:hover i.icon-download, .white-form .cta .EPiServerForms .Form__Element button.btn--white-bg:hover i.icon-download, .cta .EPiServerForms .Form__Element .white-form button.btn--white-bg:hover i.icon-download,
      .white-form .btn--white-bg:hover i {
        background-color: #1b2db5; }
  .white-form .link--white {
    color: #1b2db5; }
    .white-form .link--white::after {
      background-color: #1b2db5; }
  .white-form .FormConsentCheckbox a {
    color: #252d4f; }
    .white-form .FormConsentCheckbox a:hover {
      color: #252d4f; }

.EPiServerForms .Form__Element .Form__Element__ValidationError {
  font-size: 1.4rem; }

h1,
.h1-article,
h2,
h3,
h4,
h5 {
  color: #252d4f;
  font-family: "Galano Grotesque";
  letter-spacing: 0; }

h1 {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 4.2rem; }

.h1-article {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.2rem; }

h2 {
  font-size: 2.4rem;
  line-height: 3.2rem; }

h3 {
  font-size: 2rem;
  line-height: 2.8rem; }

h4 {
  font-size: 1.8rem;
  line-height: 2.4rem; }

h5 {
  font-size: 1.8rem;
  line-height: 2.4rem; }

.text {
  font-family: "Galano Grotesque";
  font-size: 1.6rem;
  line-height: 2.4rem; }

.text--cut, .search__results-item-description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis; }

.link, .mega-menu__secondary-column .cta-block, .mega-menu__preview .cta-block, .feature-mid-copy-block .cta-block, .feature-image-text-heading-cta-block .cta-block {
  color: #1b2db5;
  cursor: pointer;
  font-family: "Galano Grotesque";
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6rem;
  position: relative;
  text-decoration: none; }
  .link::after, .mega-menu__secondary-column .cta-block::after, .mega-menu__preview .cta-block::after, .feature-mid-copy-block .cta-block::after, .feature-image-text-heading-cta-block .cta-block::after {
    background-color: #1b2db5;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%; }
  .link::before, .mega-menu__secondary-column .cta-block::before, .mega-menu__preview .cta-block::before, .feature-mid-copy-block .cta-block::before, .feature-image-text-heading-cta-block .cta-block::before {
    background-color: #fff;
    bottom: -1px;
    content: "";
    height: 2px;
    left: -10px;
    opacity: 0;
    position: absolute;
    width: 10px;
    z-index: 5; }
  .link:hover::before, .mega-menu__secondary-column .cta-block:hover::before, .mega-menu__preview .cta-block:hover::before, .feature-mid-copy-block .cta-block:hover::before, .feature-image-text-heading-cta-block .cta-block:hover::before {
    animation: slidein 1s ease; }
  .link--white {
    color: #fff; }
    .link--white::after {
      background-color: #fff; }

.icon-round {
  border-radius: 20px;
  display: block;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  text-align: center; }
  .icon-round:hover {
    background-color: #f0f0ed; }

.btn, .hero .cta-block, .newsletter .EPiServerForms .Form__Element button, .cta .EPiServerForms a, .cta .EPiServerForms .Form__Element button {
  align-items: center;
  border: 2px solid;
  color: #1b2db5;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  font-family: "Galano Grotesque";
  font-size: 1.6rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.6rem;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  .btn--icon-right i {
    margin-left: 12px; }
  .btn i, .hero .cta-block i, .newsletter .EPiServerForms .Form__Element button i, .cta .EPiServerForms a i, .cta .EPiServerForms .Form__Element button i {
    color: #1b2db5;
    transition: all 0.2s ease-out; }
    .btn i.icon-download, .hero .cta-block i.icon-download, .newsletter .EPiServerForms .Form__Element button i.icon-download, .cta .EPiServerForms a i.icon-download, .cta .EPiServerForms .Form__Element button i.icon-download, .btn i.icon-external, .hero .cta-block i.icon-external, .newsletter .EPiServerForms .Form__Element button i.icon-external, .cta .EPiServerForms a i.icon-external, .cta .EPiServerForms .Form__Element button i.icon-external {
      background-color: #1b2db5; }
  .btn:hover, .hero .cta-block:hover, .newsletter .EPiServerForms .Form__Element button:hover, .cta .EPiServerForms a:hover, .cta .EPiServerForms .Form__Element button:hover {
    background-color: #1b2db5;
    border: 2px solid #1b2db5;
    color: #fff; }
    .btn:hover i, .hero .cta-block:hover i, .newsletter .EPiServerForms .Form__Element button:hover i, .cta .EPiServerForms a:hover i, .cta .EPiServerForms .Form__Element button:hover i {
      color: #fff; }
      .btn:hover i.icon-download, .hero .cta-block:hover i.icon-download, .newsletter .EPiServerForms .Form__Element button:hover i.icon-download, .cta .EPiServerForms a:hover i.icon-download, .cta .EPiServerForms .Form__Element button:hover i.icon-download, .btn:hover i.icon-external, .hero .cta-block:hover i.icon-external, .newsletter .EPiServerForms .Form__Element button:hover i.icon-external, .cta .EPiServerForms a:hover i.icon-external, .cta .EPiServerForms .Form__Element button:hover i.icon-external {
        background-color: #fff; }
  .btn[disabled], .hero .cta-block[disabled], .newsletter .EPiServerForms .Form__Element button[disabled], .cta .EPiServerForms a[disabled], .cta .EPiServerForms .Form__Element button[disabled] {
    background-color: #fff;
    border-color: #cdcdcd;
    color: #cdcdcd;
    opacity: 0.4;
    pointer-events: none; }
    .btn[disabled] i, .hero .cta-block[disabled] i, .newsletter .EPiServerForms .Form__Element button[disabled] i, .cta .EPiServerForms a[disabled] i, .cta .EPiServerForms .Form__Element button[disabled] i {
      color: #cdcdcd; }
      .btn[disabled] i.icon-download, .hero .cta-block[disabled] i.icon-download, .newsletter .EPiServerForms .Form__Element button[disabled] i.icon-download, .cta .EPiServerForms a[disabled] i.icon-download, .cta .EPiServerForms .Form__Element button[disabled] i.icon-download, .btn[disabled] i.icon-external, .hero .cta-block[disabled] i.icon-external, .newsletter .EPiServerForms .Form__Element button[disabled] i.icon-external, .cta .EPiServerForms a[disabled] i.icon-external, .cta .EPiServerForms .Form__Element button[disabled] i.icon-external {
        background-color: #cdcdcd; }
  .btn--white, .green-form .EPiServerForms .Form__Element button, .blue-form .EPiServerForms .Form__Element button, .hero .cta-block, .cta .EPiServerForms a {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    mix-blend-mode: screen; }
    .btn--white i, .green-form .EPiServerForms .Form__Element button i, .blue-form .EPiServerForms .Form__Element button i, .hero .cta-block i, .cta .EPiServerForms a i {
      color: #fff; }
      .btn--white i.icon-download, .green-form .EPiServerForms .Form__Element button i.icon-download, .blue-form .EPiServerForms .Form__Element button i.icon-download, .hero .cta-block i.icon-download, .cta .EPiServerForms a i.icon-download, .btn--white i.icon-external, .green-form .EPiServerForms .Form__Element button i.icon-external, .blue-form .EPiServerForms .Form__Element button i.icon-external, .hero .cta-block i.icon-external, .cta .EPiServerForms a i.icon-external {
        background-color: #fff; }
    .btn--white:hover, .green-form .EPiServerForms .Form__Element button:hover, .blue-form .EPiServerForms .Form__Element button:hover, .hero .cta-block:hover, .cta .EPiServerForms a:hover {
      background-color: #fff;
      border: 2px solid #fff;
      color: #000; }
      .btn--white:hover i, .green-form .EPiServerForms .Form__Element button:hover i, .blue-form .EPiServerForms .Form__Element button:hover i, .hero .cta-block:hover i, .cta .EPiServerForms a:hover i {
        color: #000; }
        .btn--white:hover i.icon-download, .green-form .EPiServerForms .Form__Element button:hover i.icon-download, .blue-form .EPiServerForms .Form__Element button:hover i.icon-download, .hero .cta-block:hover i.icon-download, .cta .EPiServerForms a:hover i.icon-download, .btn--white:hover i.icon-external, .green-form .EPiServerForms .Form__Element button:hover i.icon-external, .blue-form .EPiServerForms .Form__Element button:hover i.icon-external, .hero .cta-block:hover i.icon-external, .cta .EPiServerForms a:hover i.icon-external {
          background-color: #000; }
  .btn--white-bg {
    background-color: #fff;
    border-color: #fff;
    color: #1b2db5; }
    .btn--white-bg i {
      color: #1b2db5; }
    .btn--white-bg:hover {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .btn--white-bg:hover i {
        color: #fff; }
  .btn--cta, .hero .cta-block, .newsletter .EPiServerForms .Form__Element button, .cta .EPiServerForms .Form__Element button {
    padding: 14px 24px; }
  .btn--holder {
    text-align: center; }

.lock-scroll {
  overflow: hidden; }

@media (min-width: 840px) {
  h1 {
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 5.8rem; }
  .h1-article {
    font-size: 3.2rem;
    line-height: 3.8rem; }
  h2 {
    font-size: 3.2rem;
    line-height: 3.8rem; }
  h3 {
    font-size: 2.4rem;
    line-height: 3.2rem; }
  h4 {
    font-size: 2rem;
    line-height: 2.8rem; }
  h5 {
    font-size: 1.6rem;
    line-height: 2.4rem; } }

.message {
  background: rgba(48, 154, 242, 0.16);
  color: #309af2;
  font-size: 1.2rem;
  margin-bottom: 15px;
  padding: 5px 10px; }

.backend__exception-wrapper {
  margin-bottom: 30px;
  position: relative; }
  .backend__exception-wrapper--methods {
    margin-top: 15px; }
    @media (min-width: 840px) {
      .backend__exception-wrapper--methods {
        margin-top: 30px; } }

.backend__exception {
  display: block;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.58;
  padding: 10px 4px 12px 45px;
  white-space: initial; }

.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10; }
  .header__container {
    align-items: center;
    display: flex;
    height: 57px; }
  .header__right-panel {
    align-items: center;
    color: #1b2db5;
    display: flex;
    margin-left: auto;
    padding: 13px 0 13px 0; }
  .header__logo-wrap {
    padding: 13px 0 13px 12px; }
  .header__logo-img {
    width: 100px; }
  .header .search__bar {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1; }
    .header .search__bar--hidden {
      display: none; }
    .header .search__bar--none {
      display: none !important; }
  .header .search__btn-close {
    display: block;
    right: 12px; }
  .header__country-selector {
    color: #1b2db5;
    font-weight: 500; }
    .header__country-selector a {
      text-decoration: none; }
    .header__country-selector-label {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 40px; }
  .header__get-in-touch, .header__country-selector {
    display: none; }
  .header__menu {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px; }
  .header i {
    color: #1b2db5;
    cursor: pointer;
    font-size: 1.4rem; }
  .header .social-share__wrap {
    flex-direction: column; }
  .header__get-in-touch-container {
    flex-shrink: 0; }
  .header__share a {
    display: block; }

.modal-social-share__modal {
  background-color: #fff;
  box-shadow: 0 15px 20px 0 rgba(37, 45, 79, 0.5);
  display: none;
  left: 0;
  min-width: 360px;
  padding: 20px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 5; }
  .modal-social-share__modal--open {
    display: block; }
  .modal-social-share__modal .social-share__wrap {
    align-items: flex-start; }
  .modal-social-share__modal .social-share__text {
    margin-bottom: 20px; }
  .modal-social-share__modal .social-share__buttons {
    margin: 0; }
  .modal-social-share__modal .social-share__elem {
    margin-bottom: 15px; }
  .modal-social-share__modal .container {
    padding: 0; }

.social-share__wrap {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto; }

.social-share i {
  color: #1b2db5; }

.social-share__elem {
  flex-shrink: 0;
  margin-bottom: 0;
  margin-right: 24px;
  white-space: nowrap; }

.social-share__button {
  cursor: pointer; }
  .social-share__button i {
    font-size: 2.4rem; }
  .social-share__button:last-child {
    margin-right: 0; }

.social-share__text {
  color: #252d4f;
  margin-right: 24px; }

.social-share__buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0; }

.social-share__copy {
  margin-right: 8px; }

@media (max-width: 839.98px) {
  .header .search__btn-close {
    display: block;
    right: 0;
    top: -32px; } }

@media (min-width: 840px) {
  .header__logo-wrap {
    margin-right: 33px;
    padding-left: 0; }
  .header__container {
    height: 80px; }
  .header__get-in-touch, .header__country-selector {
    display: block; }
  .header__search, .header__menu, .header__get-in-touch {
    margin-left: 12px; }
  .header__search-link {
    display: block; }
  .header__menu {
    display: none; }
  .header .social-share__wrap {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start; } }

@media only screen and (min-width: 840px) and (min-width: 1200px) {
  .header .header__logo-wrap {
    padding-left: 0; } }

@media (min-width: 840px) {
  .modal-social-share {
    position: relative; }
    .modal-social-share__modal {
      left: 50%;
      top: 118px;
      transform: translate(-50%, -50%);
      width: auto; }
  .social-share__wrap {
    align-items: center;
    justify-content: center; }
  .social-share__buttons {
    flex-wrap: nowrap; } }

.footer {
  color: #fff;
  font-weight: 300; }
  .footer__container--image {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 2; }
  .footer__nav {
    margin-bottom: 80px; }
    .footer__nav-title {
      font-weight: 400;
      line-height: 2rem;
      margin-bottom: 20px; }
    .footer__nav-list-item:not(:last-of-type) {
      margin-bottom: 16px; }
    .footer__nav-list-link {
      text-decoration: none; }
  .footer__socials {
    align-items: center;
    display: flex; }
    .footer__socials-title {
      font-weight: 400;
      margin-right: 40px; }
    .footer__socials-list {
      display: flex; }
      .footer__socials-list-item {
        font-size: 2.4rem; }
        .footer__socials-list-item:not(:last-of-type) {
          margin-right: 1.6rem; }
      .footer__socials-list .cta-block {
        text-decoration: none; }
  .footer__logo {
    display: flex; }
    .footer__logo-image {
      display: block;
      max-width: 100px; }
  .footer__bottom {
    overflow: hidden;
    position: relative; }
  .footer__bg-image {
    background-position: center top;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: -5%;
    top: 0; }
    .footer__bg-image--desktop {
      z-index: 0; }
    .footer__bg-image--mobile {
      z-index: 1; }
  .footer__slogan {
    display: flex;
    flex-direction: column;
    font-family: "Foundry Gridnik";
    text-transform: uppercase; }
    .footer__slogan > b {
      font-weight: 600; }
    .footer__slogan > span {
      color: #cfd600; }

@media (min-width: 840px) {
  .footer {
    background: linear-gradient(180deg, #252d4f 0%, rgba(37, 45, 79, 0) 100%), linear-gradient(90deg, #004631 0.02%, #61a83f 27.47%, #61a83f 40.06%, #41679f 69.71%, #283583 100%);
    padding: 80px 0 0; }
    .footer__socials {
      margin-bottom: 120px; }
    .footer__logo {
      margin-bottom: 16px; }
    .footer__bottom {
      height: 635px;
      padding: 25px 0 40px; }
    .footer__bg-image--mobile {
      display: none; }
    .footer__slogan {
      font-size: 8rem;
      line-height: 6rem; }
    .footer__copyrights {
      align-self: flex-end;
      font-size: 1.4rem; } }

@media (max-width: 839.98px) {
  .footer {
    background: linear-gradient(180deg, #252d4f 0%, rgba(37, 45, 79, 0) 100%), linear-gradient(180deg, #283583 0%, #41679f 57.29%, #61a83f 76.6%, #61a83f 87.3%, #004631 99.98%);
    padding: 20px 0 0; }
    .footer__container {
      padding-left: 20px;
      padding-right: 20px; }
    .footer__nav-column:not(:last-of-type) {
      margin-bottom: 40px; }
    .footer__socials {
      margin-bottom: 80px; }
    .footer__logo {
      margin-bottom: 34px; }
    .footer__bottom {
      height: 460px;
      padding: 6px 0 40px; }
    .footer__bg-image {
      bottom: 30px;
      top: 40px; }
    .footer__slogan {
      font-size: 4.8rem;
      line-height: 3.6rem; }
    .footer__copyrights {
      font-size: 1.2rem; } }

.insight-page .breadcrumbs__col {
  width: 100%; }

.breadcrumbs {
  color: #575756;
  margin: 40px 0 20px;
  word-wrap: break-word; }
  .breadcrumbs__link {
    font-weight: 400; }
    .breadcrumbs__link::after {
      bottom: 4px;
      height: 1px; }

@media (max-width: 839.98px) {
  .breadcrumbs__col {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis; } }

@media (min-width: 840px) {
  .breadcrumbs {
    margin: 40px 0; } }

.mega-menu {
  background-color: #fff;
  box-shadow: 0 15px 20px 0 rgba(37, 45, 79, 0.5);
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: space-between;
  overflow: hidden;
  transform: translate(375px);
  width: 335px; }
  .mega-menu__holder {
    height: 100vh;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    width: 0; }
    .mega-menu__holder--open {
      width: 360px; }
  .mega-menu a:hover::before {
    animation: none !important; }
  .mega-menu--open {
    transform: translate(25px);
    transition: transform 0.2s ease-out; }
  .mega-menu__second-link {
    display: none; }
  .mega-menu__primary-item {
    cursor: pointer;
    overflow-x: hidden;
    padding: 28px 28px 28px 24px; }
    .mega-menu__primary-item--current {
      background-color: #f0f0ed; }
    .mega-menu__primary-item-label {
      align-items: center;
      display: flex;
      justify-content: space-between; }
    .mega-menu__primary-item:hover {
      background-color: #f0f0ed; }
      .mega-menu__primary-item:hover .mega-menu__link.mega-menu__title {
        border-bottom: 2px solid; }
  .mega-menu__link {
    display: inline-block;
    min-height: 2.6rem;
    text-decoration: none;
    white-space: nowrap; }
  .mega-menu__back {
    align-items: center;
    display: flex;
    padding: 28px 28px 28px 24px; }
    .mega-menu__back-holder {
      background-color: #fff;
      display: none; }
    .mega-menu__back .mega-menu__link {
      margin-left: 13px; }
    .mega-menu__back:hover .mega-menu__link {
      border-bottom: 2px solid; }
  .mega-menu__secondary-right {
    padding: 18px 24px; }
  .mega-menu__secondary-item {
    display: none; }
  .mega-menu__secondary-column {
    margin-bottom: 40px; }
    .mega-menu__secondary-column-title {
      margin-bottom: 18px; }
    .mega-menu__secondary-column-link {
      padding: 11px 0; }
    .mega-menu__secondary-column .cta-block {
      color: #252d4f;
      font-weight: normal;
      transition: none;
      width: 100%; }
      .mega-menu__secondary-column .cta-block::after {
        background-color: #fff;
        transition: none; }
      .mega-menu__secondary-column .cta-block:hover::after {
        background: #252d4f;
        color: #252d4f;
        font-weight: 500;
        width: 100%; }
  .mega-menu__desc {
    margin-bottom: 15px; }
  .mega-menu__mobile-title {
    font-size: 2.4rem;
    margin-bottom: 15px; }
  .mega-menu__preview {
    background-color: #283583;
    color: #fff;
    display: none;
    padding: 38px 24px; }
    .mega-menu__preview .cta-block {
      color: #fff;
      width: 100%; }
      .mega-menu__preview .cta-block::after {
        background-color: #fff; }
      .mega-menu__preview .cta-block:hover::after {
        background: #fff;
        color: #fff;
        width: 100%; }
  .mega-menu__button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 0 28px 0 24px; }
    .mega-menu__button .header__get-in-touch,
    .mega-menu__button .header__country-selector {
      display: block; }
    .mega-menu__button .header__get-in-touch {
      margin-left: 0; }
  .mega-menu--country-selector-open .mega-menu__back-holder {
    display: flex; }
  .mega-menu--country-selector-open .mega-menu__items, .mega-menu--country-selector-open .mega-menu__button {
    display: none; }
  .mega-menu--country-selector-open .country-selector {
    display: block; }

@media (max-width: 839.98px) {
  .mega-menu {
    position: relative; }
    .mega-menu__secondary-item {
      background-color: #fff;
      box-shadow: 0 15px 20px 0 rgba(37, 45, 79, 0.5);
      display: flex;
      flex-flow: column;
      height: 100%;
      overflow-y: hidden;
      position: absolute;
      right: -365px;
      top: 0;
      transition: right 0.2s ease-out;
      width: 335px; }
      .mega-menu__secondary-item .mega-menu__back-holder, .mega-menu__secondary-item .mega-menu__preview {
        display: block; }
    .mega-menu__secondary-right {
      overflow-y: auto; }
    .mega-menu--open-sub-menu .mega-menu__primary-item--active {
      display: block; }
      .mega-menu--open-sub-menu .mega-menu__primary-item--active:hover {
        background-color: #fff; }
      .mega-menu--open-sub-menu .mega-menu__primary-item--active .mega-menu__secondary-item {
        right: 0;
        transition: right 0.2s ease-out; }
      .mega-menu--open-sub-menu .mega-menu__primary-item--active .mega-menu__link--second {
        display: inline-block; } }

@media only screen and (min-width: 840px) and (max-width: 996px) {
  .header__logo-wrap {
    margin-right: 3px; }
  .header .container {
    padding-left: 10px;
    padding-right: 10px; }
  .header .mega-menu__primary-item {
    padding: 0 3px !important; }
    .header .mega-menu__primary-item-label {
      padding: 8px 2px; }
  .header .modal-social-share,
  .header .mega-menu__search,
  .header .mega-menu__menu,
  .header .mega-menu__get-in-touch,
  .header .mega-menu__country-selector {
    margin-left: 7px; } }

@media (min-width: 840px) {
  .mega-menu {
    box-shadow: none;
    display: flex;
    flex-direction: initial;
    height: auto;
    justify-content: initial;
    overflow: initial;
    position: initial;
    transform: none;
    width: auto; }
    .mega-menu__holder {
      display: flex;
      height: auto;
      overflow: initial;
      position: initial;
      width: auto; }
    .mega-menu__title {
      align-items: center;
      border-bottom: none;
      display: flex;
      outline-offset: 12px;
      padding: 0;
      position: relative; }
      .mega-menu__title::after {
        background-color: #283583;
        content: "";
        height: 2px;
        opacity: 0;
        position: absolute;
        top: 23px;
        width: 100%; }
    .mega-menu__items {
      display: flex;
      margin-bottom: 0; }
    .mega-menu__preview {
      display: block;
      max-width: 360px;
      padding: 40px 40px 72px 40px; }
    .mega-menu__desc {
      margin-bottom: 40px; }
    .mega-menu__secondary-item {
      background-color: #fff;
      box-shadow: 0 4px 20px 0 rgba(37, 45, 79, 0.4);
      left: 0;
      margin: 0 auto;
      max-height: 500px;
      max-width: 1128px;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 100%; }
    .mega-menu__secondary-right {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      padding: 36px 59px 40px; }
      .mega-menu__secondary-right-wrap {
        padding: 0 5px; }
    .mega-menu__secondary-column {
      margin-bottom: 0; }
      .mega-menu__secondary-column .cta-block:hover {
        font-weight: normal; }
    .mega-menu--open-sub-menu .mega-menu__primary-item--active {
      background-color: #fff; }
      .mega-menu--open-sub-menu .mega-menu__primary-item--active .mega-menu__secondary-item {
        display: flex; }
    .mega-menu__primary-item {
      align-items: center;
      display: flex;
      height: 80px;
      padding: 0 6px; }
      .mega-menu__primary-item-label {
        padding: 7px 12px 7px; }
      .mega-menu__primary-item--current {
        background-color: #fff; }
        .mega-menu__primary-item--current .mega-menu__primary-item-label {
          background-color: #283583;
          color: #fff; }
        .mega-menu__primary-item--current:hover .mega-menu__link.mega-menu__title {
          border-bottom: none; }
      .mega-menu__primary-item:hover {
        background-color: #fff; }
        .mega-menu__primary-item:hover .mega-menu__secondary-item {
          display: flex; }
        .mega-menu__primary-item:hover .mega-menu__link.mega-menu__title {
          border-bottom: none; }
          .mega-menu__primary-item:hover .mega-menu__link.mega-menu__title::after {
            opacity: 1; }
      .mega-menu__primary-item .icon-arrow {
        display: none; }
    .mega-menu__back-holder {
      display: none; }
    .mega-menu__button {
      margin-bottom: 0;
      padding: 0; } }

.country-selector {
  display: none;
  margin-bottom: auto; }
  .country-selector__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    padding-left: 24px; }
  .country-selector__close {
    display: none;
    font-size: 12px; }
    .country-selector__close i {
      color: #252d4f; }
  .country-selector__title {
    font-weight: 500; }
  .country-selector__country-link {
    align-items: center;
    color: #252d4f;
    display: flex;
    padding: 14px 24px; }
  .country-selector__country-flag {
    height: 20px;
    margin-right: 24px;
    width: 28px; }
  .country-selector__country .icon-check {
    color: #252d4f;
    display: none;
    font-size: 12px;
    margin-left: auto; }
  .country-selector__country--selected .country-selector__country-name {
    font-weight: 500; }
  .country-selector__country--selected .icon-check {
    display: block; }
  .country-selector__footer {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
  .country-selector__confirm {
    padding: 12px 24px; }
  .country-selector__modal {
    align-items: center;
    background-color: rgba(37, 45, 79, 0.8);
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 30; }
    .country-selector__modal--open {
      display: flex; }
    .country-selector__modal-dialog {
      background-color: #fff;
      max-width: 360px;
      padding: 32px 20px 40px;
      width: 33%; }
      .country-selector__modal-dialog .country-selector__country-link {
        padding: 14px 20px; }
      .country-selector__modal-dialog .country-selector__country:hover {
        background-color: #f0f0ed;
        cursor: pointer; }
      .country-selector__modal-dialog .country-selector__header {
        margin-bottom: 20px;
        padding-left: 20px; }
      .country-selector__modal-dialog .country-selector__close {
        display: block; }

@media (max-width: 839.98px) {
  .country-selector--open {
    margin-bottom: auto;
    margin-top: 18px; }
  .country-selector__footer {
    display: none; }
  .country-selector__modal {
    display: none; } }

.country-banner {
  background-color: #f0f0ed; }
  .country-banner--hidden {
    display: none; }
  .country-banner__container {
    padding: 38px 0 40px;
    position: relative; }
  .country-banner h5 {
    font-weight: 500;
    margin-bottom: 7px; }
  .country-banner__cross {
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 14px;
    top: calc(50% - 12px); }
  .country-banner__row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px; }
    .country-banner__row > * {
      margin-right: 12px;
      margin-top: 20px; }
  .country-banner__decline {
    align-items: baseline;
    display: flex;
    flex-shrink: 0; }
  .country-banner__decline .link, .country-banner__decline .mega-menu__secondary-column .cta-block, .mega-menu__secondary-column .country-banner__decline .cta-block, .country-banner__decline .mega-menu__preview .cta-block, .mega-menu__preview .country-banner__decline .cta-block, .country-banner__decline .feature-mid-copy-block .cta-block, .feature-mid-copy-block .country-banner__decline .cta-block, .country-banner__decline .feature-image-text-heading-cta-block .cta-block, .feature-image-text-heading-cta-block .country-banner__decline .cta-block {
    color: #252d4f;
    display: block;
    font-weight: 400;
    margin-left: 4px; }
    .country-banner__decline .link::after, .country-banner__decline .mega-menu__secondary-column .cta-block::after, .mega-menu__secondary-column .country-banner__decline .cta-block::after, .country-banner__decline .mega-menu__preview .cta-block::after, .mega-menu__preview .country-banner__decline .cta-block::after, .country-banner__decline .feature-mid-copy-block .cta-block::after, .feature-mid-copy-block .country-banner__decline .cta-block::after, .country-banner__decline .feature-image-text-heading-cta-block .cta-block::after, .feature-image-text-heading-cta-block .country-banner__decline .cta-block::after {
      background-color: #252d4f; }
  .country-banner__dropdown {
    background-color: #fff;
    border: 1px solid #575756;
    border-top: none;
    cursor: pointer;
    display: none;
    list-style: none;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 20; }
    .country-banner__dropdown--open {
      display: block; }
    .country-banner__dropdown-holder {
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      position: relative;
      width: 245px; }
    .country-banner__dropdown-btn {
      align-items: center;
      background-color: #fff;
      border: 1px solid #575756;
      cursor: pointer;
      display: flex;
      height: 40px;
      line-height: 1.6rem;
      overflow: hidden;
      padding: 0 12px;
      position: relative;
      width: 100%; }
    .country-banner__dropdown-img {
      flex-shrink: 0;
      height: 20px;
      margin-right: 10px;
      width: 28px; }
    .country-banner__dropdown-option {
      align-items: center;
      display: flex;
      height: 40px;
      line-height: 1.6rem;
      overflow: hidden;
      padding: 0 12px; }
      .country-banner__dropdown-option:hover {
        background-color: #f0f0ed; }
    .country-banner__dropdown-arrow {
      position: absolute;
      right: 12px;
      top: 12px; }

@media (max-width: 839.98px) {
  .country-banner h5 {
    font-size: 16px; }
  .country-banner__container {
    padding-top: 20px; }
  .country-banner__cross {
    top: 26px; } }

.pagination-holder {
  color: #1b2db5;
  display: flex;
  margin-right: -4px;
  margin-top: 40px; }
  .pagination-holder .pagination-btn {
    font-weight: 500;
    margin-right: 4px;
    text-decoration: none; }

.pagination__buttons {
  align-items: center;
  display: flex;
  flex-direction: column; }

.pagination-pages {
  display: flex; }

.pagination-ellipsis {
  line-height: 40px;
  pointer-events: none;
  text-align: center;
  user-select: none;
  width: 40px; }

.pagination-btn {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  line-height: 1.6rem;
  text-align: center;
  user-select: none;
  width: 40px; }
  .pagination-btn i {
    background-size: contain;
    filter: brightness(0) saturate(100%) invert(12%) sepia(91%) saturate(4407%) hue-rotate(238deg) brightness(85%) contrast(99%);
    height: 16px; }
  .pagination-btn--active {
    border: 2px solid #1b2db5; }
  .pagination-btn--disabled {
    pointer-events: none; }
    .pagination-btn--disabled i {
      filter: brightness(0) saturate(100%) invert(12%) sepia(91%) saturate(4407%) hue-rotate(238deg) brightness(85%) contrast(99%) opacity(20%); }

@media (min-width: 840px) {
  .pagination-btn:hover {
    background-color: #1b2db5;
    color: #fff; }
    .pagination-btn:hover i {
      filter: brightness(0) saturate(100%) invert(100%); } }

.awards-strip-block__row {
  justify-content: center; }

.awards-strip-block__col {
  align-items: center;
  display: flex;
  height: 168px;
  justify-content: center; }

.awards-strip-block__logo {
  max-height: 72px;
  max-width: 72px;
  object-fit: scale-down; }

@media (max-width: 839.98px) {
  .awards-strip-block__row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px; }
  .awards-strip-block__col {
    height: 72px;
    margin-bottom: 40px;
    width: 112px; } }

@media (min-width: 840px) {
  .awards-strip-block__logo {
    max-height: 122px;
    max-width: 115px; } }

.hero {
  position: relative; }
  .hero.small, .hero.medium, .hero.large {
    margin-top: 0; }
  .hero--primary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    position: relative; }
  .hero--narrow .hero__container {
    margin-top: -40px;
    padding-left: calc(min(3.34vw, 12px));
    padding-right: calc(min(3.34vw, 12px));
    position: initial; }
  .hero--job .hero__title {
    font-size: 3.6rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 4.2rem; }
  .hero__job-btn {
    display: flex;
    justify-content: flex-start;
    margin-top: 59px;
    width: 100%; }
  .hero__bg {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 0; }
    .hero__bg-image {
      background-position: center;
      background-size: cover;
      width: 100%; }
  .hero__container {
    position: relative;
    z-index: 1; }
  .hero__block {
    background-color: #283583;
    color: #fff;
    padding: 40px;
    position: relative; }
    .hero__block-content {
      align-items: center;
      display: flex; }
  .hero__title {
    color: #fff;
    margin-bottom: 20px; }
  .hero__description {
    font-weight: 300;
    margin: 0; }
  .hero .cta-block {
    flex-shrink: 0; }

@media (min-width: 840px) {
  .hero--primary {
    height: 600px; }
    .hero--primary .hero__block {
      bottom: 40px; }
      .hero--primary .hero__block-content > div:last-child {
        margin-right: 0; }
    .hero--primary.hero--narrow {
      height: 564px; }
  .hero--narrow {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: calc(min(3vw, 36px));
    padding-right: calc(min(3vw, 36px)); }
    .hero--narrow.hero--right .hero__container {
      left: initial;
      right: 132px; }
    .hero--narrow .hero__bg {
      position: initial; }
    .hero--narrow .hero__container {
      left: 132px;
      margin-top: 0;
      padding: 0;
      position: absolute; }
    .hero--narrow h1 {
      font-size: 3.2rem;
      line-height: 4.2rem; }
  .hero--secondary {
    height: 525px;
    overflow: hidden; }
  .hero--job .hero__title {
    font-size: 3.2rem;
    line-height: 3.8rem; }
  .hero__job-btn {
    justify-content: flex-end; }
  .hero__bg--mobile {
    display: none; }
  .hero__bg-image {
    height: 525px; }
  .hero__block-wrapper--secondary {
    transform: translateY(-100%); }
  .hero__title--primary {
    font-size: 4.8rem;
    line-height: 5.8rem; }
  .hero__title--secondary {
    font-size: 3.2rem;
    line-height: 3.8rem; }
  .hero__description {
    margin-right: 40px; }
  .hero .cta-block {
    margin-left: auto; } }

@media (max-width: 839.98px) {
  .hero__bg {
    height: 360px;
    position: static !important; }
    .hero__bg--mobile {
      margin-top: -360px; }
    .hero__bg-image {
      height: 225px; }
  .hero__container {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0; }
  .hero__block {
    padding: 20px; }
    .hero__block-wrapper--secondary {
      margin: -40px 4px 0; }
    .hero__block-content {
      align-items: flex-start;
      flex-direction: column; }
  .hero__title--primary {
    font-size: 3.6rem;
    line-height: 4.2rem; }
  .hero__title--secondary {
    font-size: 2.4rem;
    line-height: 3.2rem; }
  .hero .cta-block {
    margin: 20px 0 0 0; } }

.service-teaser__item {
  display: flex;
  flex-direction: column;
  text-decoration: none; }
  .service-teaser__item-image {
    width: 100%; }
  .service-teaser__item-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column; }
  .service-teaser__item-link {
    display: inline; }
    .service-teaser__item-link::before, .service-teaser__item-link::after {
      bottom: -4px; }

@media (min-width: 840px) {
  .service-teaser__item {
    height: 100%; }
    .service-teaser__item:hover {
      background-color: #edf3ff; }
      .service-teaser__item:hover .link::before, .service-teaser__item:hover .mega-menu__secondary-column .cta-block::before, .mega-menu__secondary-column .service-teaser__item:hover .cta-block::before, .service-teaser__item:hover .mega-menu__preview .cta-block::before, .mega-menu__preview .service-teaser__item:hover .cta-block::before, .service-teaser__item:hover .feature-mid-copy-block .cta-block::before, .feature-mid-copy-block .service-teaser__item:hover .cta-block::before, .service-teaser__item:hover .feature-image-text-heading-cta-block .cta-block::before, .feature-image-text-heading-cta-block .service-teaser__item:hover .cta-block::before {
        animation: slidein 1s ease; }
    .service-teaser__item-content {
      height: 100%;
      padding: 40px; }
    .service-teaser__item-badge {
      margin-bottom: 16px; }
    .service-teaser__item-title {
      font-size: 3.2rem;
      line-height: 3.8rem;
      margin-bottom: 40px; }
    .service-teaser__item-link {
      margin-top: auto; } }

@media (max-width: 839.98px) {
  .service-teaser .row {
    margin-bottom: -20px; }
  .service-teaser__item {
    margin-bottom: 20px; }
    .service-teaser__item-content {
      padding: 20px 0; }
    .service-teaser__item-badge {
      margin-bottom: 20px; }
    .service-teaser__item-title {
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 20px; } }

.feature-mid-copy-block {
  overflow: hidden; }
  .feature-mid-copy-block--right .row {
    flex-direction: row-reverse; }
  .feature-mid-copy-block__image {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
    .feature-mid-copy-block__image-wrapper {
      position: relative; }
      .feature-mid-copy-block__image-wrapper::before {
        content: "";
        display: block;
        padding-top: 100%; }
  .feature-mid-copy-block__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center; }
    .feature-mid-copy-block__content-description {
      margin-bottom: 20px; }
    .feature-mid-copy-block__content-title {
      margin-bottom: 20px; }

@media (min-width: 840px) {
  .feature-mid-copy-block__image-wrapper--lg {
    margin-bottom: -160px; }
  .feature-mid-copy-block__content-badge {
    margin-bottom: 16px; }
  .feature-mid-copy-block__content-title {
    font-size: 3.2rem;
    line-height: 3.8rem; } }

@media (max-width: 839.98px) {
  .feature-mid-copy-block__image-wrapper--lg {
    margin-bottom: -80px; }
  .feature-mid-copy-block__content {
    padding-top: 20px; }
    .feature-mid-copy-block__content-badge {
      margin-bottom: 20px; }
    .feature-mid-copy-block__content-title {
      font-size: 2.4rem;
      line-height: 3.2rem; } }

.image-full-width__image {
  display: block;
  margin-bottom: 20px;
  width: 100%; }

.image-full-width__text {
  margin: 0 auto;
  max-width: 744px; }

@media (max-width: 839.98px) {
  .image-full-width__text {
    font-size: 1.4rem;
    line-height: 2rem; } }

.testimonial-block__inner {
  margin-bottom: 20px; }

.testimonial-block__data {
  padding-bottom: 24px;
  padding-top: 40px;
  position: relative; }
  .testimonial-block__data::before, .testimonial-block__data::after {
    border-top: 4px solid #61a83f;
    content: "";
    position: absolute;
    transition: width 1s 0.25s;
    width: 0; }
  .testimonial-block__data--filled::before, .testimonial-block__data--filled::after {
    transition: width 1s 0.25s;
    width: 50%; }
  .testimonial-block__data::before {
    top: 0; }
  .testimonial-block__data::after {
    bottom: 0;
    right: 8px; }

.testimonial-block__controls {
  display: none; }

.testimonial-block__quote {
  margin-bottom: 20px; }

.testimonial-block__person-full-name {
  font-weight: 500; }

.testimonial-block__person-company-logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: 60px;
  margin-top: 20px; }

.tns-nav {
  display: flex;
  justify-content: center;
  width: 100%; }
  .tns-nav button {
    background-color: #f0f0ed;
    border: none;
    border-radius: 4px;
    display: block;
    height: 8px;
    margin: 0 4px;
    width: 8px; }
    .tns-nav button.tns-nav-active {
      background-color: #283583; }

@media (min-width: 840px) {
  .testimonial-block__inner {
    margin-bottom: 40px;
    width: 80%; }
  .testimonial-block__data {
    padding: 84px 0 44px; }
    .testimonial-block__data::after {
      right: 0; }
  .testimonial-block__controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1; }
    .testimonial-block__controls:focus {
      outline: none; }
    .testimonial-block__controls li .icon-round {
      position: relative; }
    .testimonial-block__controls li i {
      filter: invert(15%) sepia(81%) saturate(7493%) hue-rotate(240deg) brightness(72%) contrast(95%);
      left: calc(50% - 4px);
      position: absolute;
      top: calc(50% - 7px); }
    .testimonial-block__controls li:hover {
      cursor: pointer; }
    .testimonial-block__controls li[aria-disabled=true] .icon-round {
      display: none; } }

.text-center-block__title {
  margin-bottom: 20px; }

.image-text-block {
  overflow: hidden; }
  .image-text-block__image {
    display: block;
    width: 100%; }
  .image-text-block__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center; }

@media (min-width: 840px) {
  .image-text-block--right .row {
    flex-direction: row-reverse; }
  .image-text-block__title {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px; } }

@media (max-width: 839.98px) {
  .image-text-block--heading .row {
    flex-direction: column-reverse; }
  .image-text-block--heading .image-text-block__text {
    margin-bottom: 20px; }
  .image-text-block:not(.image-text-block--heading) .image-text-block__image {
    margin-bottom: 20px; }
  .image-text-block__title {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 20px; } }

.feature-image-text-heading-cta-block {
  overflow: hidden; }
  .feature-image-text-heading-cta-block__image {
    display: block;
    width: 100%; }
  .feature-image-text-heading-cta-block__content :last-child {
    margin-bottom: 0; }
  .feature-image-text-heading-cta-block__content-badge, .feature-image-text-heading-cta-block__content-title, .feature-image-text-heading-cta-block__content-description {
    margin-bottom: 20px; }

@media (min-width: 840px) {
  .feature-image-text-heading-cta-block__content-description {
    margin-bottom: 40px; } }

.video__description {
  margin-top: 20px; }

@media (min-width: 840px) {
  .video__description {
    margin-top: 22px; } }

.speakers-block h4 {
  margin-bottom: 40px; }

.speakers-block__impact {
  margin-bottom: 40px; }

.speakers-block .page-details__row {
  margin-top: 0; }

.speakers-block .person-block {
  margin-bottom: 80px; }
  .speakers-block .person-block .page-details__row .page-details__author-links {
    color: #1b2db5;
    display: flex;
    font-size: 1.8rem; }
  .speakers-block .person-block .page-details__col {
    flex-basis: auto; }

.speakers-block__row {
  margin-bottom: -80px; }

.speakers-block--horizontal .person-block .page-details__row {
  align-items: flex-start;
  flex-direction: column; }

.speakers-block--horizontal .page-details__author-bio {
  margin-top: 20px; }

.speakers-block--horizontal .page-details__author-image {
  margin-bottom: 40px; }

.speakers-block--horizontal .page-details__author-links {
  flex-wrap: wrap;
  margin-top: 15px;
  position: static; }

.speakers-block--horizontal .page-details__col span {
  white-space: initial; }

@media (max-width: 839.98px) {
  .speakers-block h4 {
    margin-bottom: 20px; }
  .speakers-block__impact {
    margin-bottom: 80px; }
  .speakers-block .person-block {
    margin-bottom: 40px; }
  .speakers-block__row {
    margin-bottom: -40px; } }

.related-block {
  padding-left: calc(min(3.34vw, 12px)); }
  .related-block__title {
    margin-bottom: 23px; }
  .related-block__container {
    display: flex; }
    .related-block__container.tns-horizontal.tns-subpixel > .card.tns-item {
      display: flex; }
  .related-block__wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px; }
  .related-block__cta {
    margin-top: 40px;
    text-align: center; }
  .related-block .card {
    border-bottom: none;
    margin-bottom: 0;
    margin-right: 16px;
    max-width: 248px;
    width: 248px; }
    .related-block .card__img {
      height: 170px;
      width: 248px; }
    .related-block .card__img-wrap {
      max-width: 248px;
      width: 248px; }
    .related-block .card__title, .related-block .card__read-time, .related-block .card__infographic, .related-block .card__video, .related-block .card__webinar, .related-block .card__event, .related-block .card__whitepaper, .related-block .card__recwebinar, .related-block .card__factsheet, .related-block .card__link-text {
      padding: 0; }

@media (min-width: 840px) {
  .related-block {
    padding-left: calc(min(3vw, 36px)); }
    .related-block__title {
      margin-bottom: 38px; } }

@media only screen and (min-width: 1200px) {
  .related-block {
    padding-right: calc(min(3vw, 36px)); }
    .related-block .card {
      margin-bottom: 0;
      margin-right: 24px;
      max-width: 360px;
      width: 360px; }
      .related-block .card__img {
        height: 225px;
        width: 360px; }
      .related-block .card__img-wrap {
        max-width: 360px;
        width: 360px; }
      .related-block .card__title, .related-block .card__read-time, .related-block .card__infographic, .related-block .card__video, .related-block .card__webinar, .related-block .card__event, .related-block .card__whitepaper, .related-block .card__recwebinar, .related-block .card__factsheet, .related-block .card__link-text {
        padding: 0 20px; }
    .related-block--small .card {
      max-width: 264px;
      width: 264px; }
      .related-block--small .card__img {
        height: 165px;
        width: 264px; }
      .related-block--small .card__img-wrap {
        max-width: 264px;
        width: 264px; } }

.quote-block {
  overflow: hidden; }
  .quote-block__info {
    box-sizing: content-box;
    width: calc(min(46vw, 552px));
    z-index: 1; }
  .quote-block__outer {
    background-color: #fff;
    border: 1px solid #f0f0ed; }
  .quote-block__inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(min(46vw, 552px));
    padding: calc(min(2vw, 24px)) calc(min(6vw, 72px)); }
  .quote-block__quote {
    margin-bottom: 20px; }
    .quote-block__quote a {
      font-size: inherit;
      line-height: inherit; }
  .quote-block__img {
    display: block;
    width: 100%; }

@media (min-width: 840px) {
  .quote-block__row {
    align-items: center; }
  .quote-block__info {
    margin-right: -50%; } }

@media (max-width: 839.98px) {
  .quote-block__info {
    margin: auto;
    order: 1;
    width: 93.32vw; }
  .quote-block__outer {
    margin: -20px 10px 0; }
  .quote-block__inner {
    min-height: calc(93.32vw - 20px);
    padding: 6vw; }
  .quote-block__quote {
    font-size: 18px;
    line-height: 24px; } }

.image-teaser-block {
  overflow: hidden; }
  .image-teaser-block--right .row {
    flex-direction: row-reverse; }
  .image-teaser-block--indent {
    margin-bottom: 20px; }
  .image-teaser-block__impact {
    margin-bottom: 20px; }
  .image-teaser-block__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-top: 20px; }
  .image-teaser-block__link {
    border: 2px solid #1b2db5;
    display: block;
    margin-bottom: 10px;
    padding: 25px 20px 28px 24px;
    text-decoration: none; }
    .image-teaser-block__link:last-child {
      margin-bottom: 0; }
    .image-teaser-block__link-title {
      color: #1b2db5;
      font-weight: 500;
      margin-bottom: 23px; }
    .image-teaser-block__link:hover {
      background-color: #1b2db5;
      color: #fff; }
      .image-teaser-block__link:hover .image-teaser-block__link-title {
        color: #fff; }

@media (min-width: 840px) {
  .image-teaser-block__content {
    display: block;
    padding-top: 0; }
  .image-teaser-block__impact {
    margin-bottom: 40px; }
  .image-teaser-block__pic-1 {
    width: 60%; }
  .image-teaser-block__pic-2 {
    width: 80%; }
  .image-teaser-block .feature-mid-copy-block__image-wrapper--lg {
    margin-bottom: -90px; }
  .image-teaser-block__link {
    margin-bottom: 20px;
    padding: 20px 56px 20px 24px; }
  .image-teaser-block__img {
    margin-top: 68px; } }

.feature-interactive-block {
  background-color: #fff;
  position: relative;
  z-index: 1; }
  .feature-interactive-block__image {
    display: block;
    max-width: 838px;
    width: 100%; }
  .feature-interactive-block__bullet-title {
    display: none; }
  .feature-interactive-block__title {
    margin-bottom: 22px; }
  .feature-interactive-block__text {
    background-color: #fff;
    margin: -20px 10px 0 10px;
    padding: 20px 20px 30px 25px;
    position: relative; }

@media (min-width: 840px) {
  .feature-interactive-block__title {
    margin-bottom: 30px; }
  .feature-interactive-block__wrap {
    position: relative; }
  .feature-interactive-block__image-wrap {
    margin-left: 288px; }
  .feature-interactive-block__text {
    margin-left: 456px;
    margin-top: 0;
    padding: 25px 0 0 24px; }
  .feature-interactive-block__bullet-title {
    display: flex;
    height: 100%;
    width: 100%; }
    .feature-interactive-block__bullet-title a {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 100%;
      text-decoration: navajowhite; }
    .feature-interactive-block__bullet-title span {
      align-items: center;
      cursor: default;
      display: flex;
      flex-grow: 1;
      height: 100%;
      text-decoration: navajowhite; }
  .feature-interactive-block .tns-nav {
    background-color: #fff;
    border: 2px solid #f0f0ed;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 34px 32px 60px 37px;
    position: absolute;
    top: 85px;
    width: 456px; }
    .feature-interactive-block .tns-nav button {
      align-items: center;
      background-color: #fff;
      border-radius: 0;
      color: #1b2db5;
      display: flex;
      font-weight: 500;
      margin: 0;
      min-height: 48px;
      width: 100%; }
      .feature-interactive-block .tns-nav button.tns-nav-active {
        background-color: #1b2db5;
        color: #fff;
        padding-left: 22px; } }

.icon-grid-card-block,
.stats-grid-card-block,
.icon-grid-card-block__link {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  text-align: center; }
  .icon-grid-card-block__link,
  .stats-grid-card-block__link,
  .icon-grid-card-block__link__link {
    line-height: 24px;
    margin-bottom: 0;
    text-decoration: none; }
    .icon-grid-card-block__link:hover,
    .stats-grid-card-block__link:hover,
    .icon-grid-card-block__link__link:hover {
      background-color: #edf3ff; }

.icon-grid-card-block__img {
  display: block;
  width: 72px; }

.icon-grid-card-block__title {
  font-weight: 500;
  margin-top: 20px; }

.icon-grid-card-block__description {
  margin-top: 10px; }

.stats-grid-card-block__stat {
  font-size: 32px;
  line-height: 38px; }

.icon-text-grid-block__title, .icon-text-grid-block__impact {
  margin-bottom: 20px; }

.icon-text-grid-block__row {
  margin-bottom: -40px; }

@media (min-width: 840px) {
  .icon-text-grid-block__impact {
    margin-bottom: 40px; }
  .stats-grid-card-block__title {
    margin-top: 10px; } }

.expertise-grid-block__links {
  display: flex;
  flex-direction: column; }

.expertise-grid-block__link {
  align-items: center;
  background-color: #fff;
  color: #1b2db5;
  display: flex;
  min-height: 64px;
  outline-offset: -2px;
  padding-left: 64px;
  padding-right: 5px;
  position: relative;
  text-decoration: none; }
  .expertise-grid-block__link::before {
    background-color: #fff;
    border: 2px solid #1b2db5;
    border-radius: 100%;
    content: "";
    height: 12px;
    left: 25px;
    position: absolute;
    width: 12px; }
  .expertise-grid-block__link:hover {
    background-color: #1b2db5;
    color: #fff; }
    .expertise-grid-block__link:hover::before {
      background-color: #1b2db5;
      border-color: #fff; }

@media (min-width: 840px) {
  .expertise-grid-block__links {
    flex-direction: row;
    flex-wrap: wrap; }
  .expertise-grid-block__link {
    width: 360px; } }

.card {
  border-bottom: 2px solid #f0f0ed;
  display: flex;
  margin-bottom: 40px;
  margin-right: 16px;
  max-width: 160px;
  position: relative;
  width: auto; }
  .card__title, .card__read-time, .card__infographic, .card__video, .card__webinar, .card__event, .card__whitepaper, .card__recwebinar, .card__factsheet {
    align-items: center;
    margin-bottom: 20px; }
  .card__title {
    flex-grow: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis; }
  .card__webinar {
    flex-wrap: wrap; }
  .card__img-wrap {
    margin-bottom: 20px;
    max-width: 160px; }
  .card__img {
    display: block;
    height: 100px;
    max-width: 100%;
    width: 100%; }
  .card__time i, .card__read-time i, .card__infographic i, .card__video i, .card__webinar i, .card__event i, .card__whitepaper i, .card__recwebinar i, .card__factsheet i {
    margin-right: 8px; }
  .card--small-spec-prop .card__prop, .cards--two-results .card .card__prop, .cards--three-results .card:nth-child(1) .card__prop, .cards--three-results .card:nth-child(2) .card__prop, .cards--four-results .card .card__prop, .cards--five-results .card:nth-child(1) .card__prop, .cards--five-results .card:nth-child(2) .card__prop, .cards--five-results .card:nth-child(3) .card__prop, .cards--five-results .card:nth-child(4) .card__prop, .cards--more-five-results .card:nth-child(6n + 1) .card__prop, .cards--more-five-results .card:nth-child(6n + 2) .card__prop, .cards--more-five-results .card:nth-child(6n + 3) .card__prop, .cards--more-five-results .card:nth-child(6n + 4) .card__prop {
    font-size: 1.4rem; }
    .card--small-spec-prop .card__prop i, .cards--two-results .card .card__prop i, .cards--three-results .card:nth-child(1) .card__prop i, .cards--three-results .card:nth-child(2) .card__prop i, .cards--four-results .card .card__prop i, .cards--five-results .card:nth-child(1) .card__prop i, .cards--five-results .card:nth-child(2) .card__prop i, .cards--five-results .card:nth-child(3) .card__prop i, .cards--five-results .card:nth-child(4) .card__prop i, .cards--more-five-results .card:nth-child(6n + 1) .card__prop i, .cards--more-five-results .card:nth-child(6n + 2) .card__prop i, .cards--more-five-results .card:nth-child(6n + 3) .card__prop i, .cards--more-five-results .card:nth-child(6n + 4) .card__prop i {
      background-size: contain;
      margin-right: 6px; }
  .card--small-spec-prop .card__read-time i, .cards--two-results .card .card__read-time i, .cards--three-results .card:nth-child(1) .card__read-time i, .cards--three-results .card:nth-child(2) .card__read-time i, .cards--four-results .card .card__read-time i, .cards--five-results .card:nth-child(1) .card__read-time i, .cards--five-results .card:nth-child(2) .card__read-time i, .cards--five-results .card:nth-child(3) .card__read-time i, .cards--five-results .card:nth-child(4) .card__read-time i, .cards--more-five-results .card:nth-child(6n + 1) .card__read-time i, .cards--more-five-results .card:nth-child(6n + 2) .card__read-time i, .cards--more-five-results .card:nth-child(6n + 3) .card__read-time i, .cards--more-five-results .card:nth-child(6n + 4) .card__read-time i,
  .card--small-spec-prop .card__video i,
  .cards--two-results .card .card__video i,
  .cards--three-results .card:nth-child(1) .card__video i,
  .cards--three-results .card:nth-child(2) .card__video i,
  .cards--four-results .card .card__video i,
  .cards--five-results .card:nth-child(1) .card__video i,
  .cards--five-results .card:nth-child(2) .card__video i,
  .cards--five-results .card:nth-child(3) .card__video i,
  .cards--five-results .card:nth-child(4) .card__video i,
  .cards--more-five-results .card:nth-child(6n + 1) .card__video i,
  .cards--more-five-results .card:nth-child(6n + 2) .card__video i,
  .cards--more-five-results .card:nth-child(6n + 3) .card__video i,
  .cards--more-five-results .card:nth-child(6n + 4) .card__video i,
  .card--small-spec-prop .card__whitepaper i,
  .cards--two-results .card .card__whitepaper i,
  .cards--three-results .card:nth-child(1) .card__whitepaper i,
  .cards--three-results .card:nth-child(2) .card__whitepaper i,
  .cards--four-results .card .card__whitepaper i,
  .cards--five-results .card:nth-child(1) .card__whitepaper i,
  .cards--five-results .card:nth-child(2) .card__whitepaper i,
  .cards--five-results .card:nth-child(3) .card__whitepaper i,
  .cards--five-results .card:nth-child(4) .card__whitepaper i,
  .cards--more-five-results .card:nth-child(6n + 1) .card__whitepaper i,
  .cards--more-five-results .card:nth-child(6n + 2) .card__whitepaper i,
  .cards--more-five-results .card:nth-child(6n + 3) .card__whitepaper i,
  .cards--more-five-results .card:nth-child(6n + 4) .card__whitepaper i,
  .card--small-spec-prop .card__factsheet i,
  .cards--two-results .card .card__factsheet i,
  .cards--three-results .card:nth-child(1) .card__factsheet i,
  .cards--three-results .card:nth-child(2) .card__factsheet i,
  .cards--four-results .card .card__factsheet i,
  .cards--five-results .card:nth-child(1) .card__factsheet i,
  .cards--five-results .card:nth-child(2) .card__factsheet i,
  .cards--five-results .card:nth-child(3) .card__factsheet i,
  .cards--five-results .card:nth-child(4) .card__factsheet i,
  .cards--more-five-results .card:nth-child(6n + 1) .card__factsheet i,
  .cards--more-five-results .card:nth-child(6n + 2) .card__factsheet i,
  .cards--more-five-results .card:nth-child(6n + 3) .card__factsheet i,
  .cards--more-five-results .card:nth-child(6n + 4) .card__factsheet i,
  .card--small-spec-prop .card__infographic i,
  .cards--two-results .card .card__infographic i,
  .cards--three-results .card:nth-child(1) .card__infographic i,
  .cards--three-results .card:nth-child(2) .card__infographic i,
  .cards--four-results .card .card__infographic i,
  .cards--five-results .card:nth-child(1) .card__infographic i,
  .cards--five-results .card:nth-child(2) .card__infographic i,
  .cards--five-results .card:nth-child(3) .card__infographic i,
  .cards--five-results .card:nth-child(4) .card__infographic i,
  .cards--more-five-results .card:nth-child(6n + 1) .card__infographic i,
  .cards--more-five-results .card:nth-child(6n + 2) .card__infographic i,
  .cards--more-five-results .card:nth-child(6n + 3) .card__infographic i,
  .cards--more-five-results .card:nth-child(6n + 4) .card__infographic i {
    height: 14px;
    width: 14px; }
  .card--small-spec-prop .card__recwebinar i, .cards--two-results .card .card__recwebinar i, .cards--three-results .card:nth-child(1) .card__recwebinar i, .cards--three-results .card:nth-child(2) .card__recwebinar i, .cards--four-results .card .card__recwebinar i, .cards--five-results .card:nth-child(1) .card__recwebinar i, .cards--five-results .card:nth-child(2) .card__recwebinar i, .cards--five-results .card:nth-child(3) .card__recwebinar i, .cards--five-results .card:nth-child(4) .card__recwebinar i, .cards--more-five-results .card:nth-child(6n + 1) .card__recwebinar i, .cards--more-five-results .card:nth-child(6n + 2) .card__recwebinar i, .cards--more-five-results .card:nth-child(6n + 3) .card__recwebinar i, .cards--more-five-results .card:nth-child(6n + 4) .card__recwebinar i,
  .card--small-spec-prop .card__event i,
  .cards--two-results .card .card__event i,
  .cards--three-results .card:nth-child(1) .card__event i,
  .cards--three-results .card:nth-child(2) .card__event i,
  .cards--four-results .card .card__event i,
  .cards--five-results .card:nth-child(1) .card__event i,
  .cards--five-results .card:nth-child(2) .card__event i,
  .cards--five-results .card:nth-child(3) .card__event i,
  .cards--five-results .card:nth-child(4) .card__event i,
  .cards--more-five-results .card:nth-child(6n + 1) .card__event i,
  .cards--more-five-results .card:nth-child(6n + 2) .card__event i,
  .cards--more-five-results .card:nth-child(6n + 3) .card__event i,
  .cards--more-five-results .card:nth-child(6n + 4) .card__event i,
  .card--small-spec-prop .card__webinar i,
  .cards--two-results .card .card__webinar i,
  .cards--three-results .card:nth-child(1) .card__webinar i,
  .cards--three-results .card:nth-child(2) .card__webinar i,
  .cards--four-results .card .card__webinar i,
  .cards--five-results .card:nth-child(1) .card__webinar i,
  .cards--five-results .card:nth-child(2) .card__webinar i,
  .cards--five-results .card:nth-child(3) .card__webinar i,
  .cards--five-results .card:nth-child(4) .card__webinar i,
  .cards--more-five-results .card:nth-child(6n + 1) .card__webinar i,
  .cards--more-five-results .card:nth-child(6n + 2) .card__webinar i,
  .cards--more-five-results .card:nth-child(6n + 3) .card__webinar i,
  .cards--more-five-results .card:nth-child(6n + 4) .card__webinar i {
    height: 19px;
    width: 19px; }
  .card--small-spec-prop .icon-location, .cards--two-results .card .icon-location, .cards--three-results .card:nth-child(1) .icon-location, .cards--three-results .card:nth-child(2) .icon-location, .cards--four-results .card .icon-location, .cards--five-results .card:nth-child(1) .icon-location, .cards--five-results .card:nth-child(2) .icon-location, .cards--five-results .card:nth-child(3) .icon-location, .cards--five-results .card:nth-child(4) .icon-location, .cards--more-five-results .card:nth-child(6n + 1) .icon-location, .cards--more-five-results .card:nth-child(6n + 2) .icon-location, .cards--more-five-results .card:nth-child(6n + 3) .icon-location, .cards--more-five-results .card:nth-child(6n + 4) .icon-location {
    flex-shrink: 0; }
  .card__desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .card__event-time {
    margin-right: 25px; }
  .card__link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    outline-offset: -1px;
    padding-bottom: 40px;
    text-decoration: none;
    width: 100%; }
    .card__link:hover .card__type-label {
      background-color: #1b2db5;
      color: #fff; }
  .card__type-label {
    background-color: #f0f0ed;
    font-size: 1.4rem;
    font-weight: 500;
    left: 8px;
    letter-spacing: 0;
    line-height: 2.4rem;
    padding: 0 8px;
    position: absolute;
    top: 8px;
    white-space: nowrap;
    width: initial; }
    .card__type-label--no-img {
      margin-bottom: 16px;
      margin-left: 20px;
      position: initial; }

.pagination {
  margin-bottom: 40px;
  text-align: center; }

@media (min-width: 840px) {
  .card {
    border-bottom: none;
    margin-bottom: 46px;
    margin-right: 24px;
    max-width: 264px;
    width: 264px; }
    .card__title, .card__read-time, .card__infographic, .card__video, .card__webinar, .card__event, .card__whitepaper, .card__recwebinar, .card__factsheet {
      padding: 0 20px; }
    .card__link {
      padding-bottom: 24px; }
      .card__link-text {
        padding: 0 20px; }
      .card__link:hover {
        background-color: #edf3ff; }
    .card__img-wrap {
      max-width: 264px; }
    .card__img {
      height: 165px;
      width: 264px; } }

@media only screen and (min-width: 1200px) {
  .card:nth-child(4n + 4) {
    margin-right: 0; } }

.cards__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 360px; }
  .cards__container .card__title {
    flex-grow: 0; }
  .cards__container .card__prop {
    margin-top: auto; }

.cards--one-result .card {
  margin-right: 0;
  max-width: 336px; }
  .cards--one-result .card__img-wrap {
    max-width: 336px; }
  .cards--one-result .card .card__img {
    height: 210px; }

.cards--two-results .card {
  margin-right: 0;
  max-width: 176px;
  padding-right: 16px;
  width: 176px; }
  .cards--two-results .card:last-child {
    margin-right: 0;
    max-width: 160px;
    padding-right: 0;
    width: auto; }
  .cards--two-results .card__img-wrap {
    max-width: 176px; }

.cards--three-results .card {
  margin-right: 0;
  max-width: 176px;
  padding-right: 16px;
  width: 176px; }
  .cards--three-results .card:nth-child(2) {
    margin-right: 0;
    max-width: 160px;
    padding-right: 0;
    width: auto; }
  .cards--three-results .card__img-wrap {
    max-width: 176px; }
  .cards--three-results .card:nth-child(3) {
    margin-right: 0;
    max-width: 336px;
    padding-right: 0;
    width: auto; }
    .cards--three-results .card:nth-child(3) .card__img-wrap {
      max-width: 336px; }
    .cards--three-results .card:nth-child(3) .card__img {
      height: 210px; }

.cards--four-results .card {
  margin-right: 0;
  max-width: 176px;
  padding-right: 16px;
  width: 176px; }
  .cards--four-results .card:nth-child(2n) {
    margin-right: 0;
    max-width: 160px;
    padding-right: 0;
    width: auto; }
  .cards--four-results .card__img-wrap {
    max-width: 176px; }

.cards--five-results .card {
  margin-right: 0;
  max-width: 176px;
  padding-right: 16px;
  width: 176px; }
  .cards--five-results .card:nth-child(2), .cards--five-results .card:nth-child(4) {
    margin-right: 0;
    max-width: 160px;
    padding-right: 0;
    width: auto; }
  .cards--five-results .card__img-wrap {
    max-width: 176px; }
  .cards--five-results .card:nth-child(5) {
    margin-right: 0;
    max-width: 336px;
    padding-right: 0;
    width: auto; }
    .cards--five-results .card:nth-child(5) .card__img-wrap {
      max-width: 336px; }
    .cards--five-results .card:nth-child(5) .card__img {
      height: 210px; }

.cards--more-five-results .card {
  margin-right: 0;
  max-width: initial;
  padding-right: 16px;
  width: 176px; }
  .cards--more-five-results .card__img-wrap {
    max-width: initial;
    width: 160px; }
  .cards--more-five-results .card:nth-child(6n + 2), .cards--more-five-results .card:nth-child(6n + 4) {
    margin-right: 0;
    padding-right: 0;
    width: 160px; }
  .cards--more-five-results .card:nth-child(6n + 3) {
    width: 176px; }
    .cards--more-five-results .card:nth-child(6n + 3) .card__img-wrap {
      width: 160px; }
  .cards--more-five-results .card:nth-child(6n + 5) {
    margin-right: 0;
    padding-right: 0;
    width: 336px; }
    .cards--more-five-results .card:nth-child(6n + 5) .card__img-wrap {
      width: 336px; }
    .cards--more-five-results .card:nth-child(6n + 5) .card__img {
      height: 210px; }
  .cards--more-five-results .card:nth-child(6n) {
    margin-right: 0;
    padding-right: 0;
    width: 336px; }
    .cards--more-five-results .card:nth-child(6n) .card__img-wrap {
      display: none; }
    .cards--more-five-results .card:nth-child(6n) .card__type-label {
      align-self: flex-start;
      margin-bottom: 16px;
      margin-left: 0;
      position: initial; }

@media (min-width: 840px) {
  .cards__container {
    justify-content: flex-start;
    max-width: initial; } }

@media only screen and (max-width: 362px) {
  .cards--more-five-results .card {
    width: 50%; }
    .cards--more-five-results .card:nth-child(6n + 5) {
      width: 100%; }
    .cards--more-five-results .card:nth-child(6n + 3) {
      width: 50%; }
    .cards--more-five-results .card:nth-child(6n + 2), .cards--more-five-results .card:nth-child(6n + 4) {
      width: 50%; }
    .cards--more-five-results .card .card__img-wrap {
      width: 100% !important; } }

.filter {
  margin-bottom: 40px; }
  .filter:last-child {
    margin-bottom: 0; }
  .filter__apply-d {
    display: none; }
  .filter__apply-d, .filter__apply-m {
    background: white;
    bottom: 0;
    left: 0;
    padding: 15px 0 20px;
    position: absolute;
    width: 95%; }
    .filter__apply-d span, .filter__apply-m span {
      pointer-events: none; }
  .filter__label {
    display: flex; }
    .filter__label-text {
      flex: 1; }
  .filter__title {
    display: block;
    font-weight: bold;
    margin-bottom: 20px; }
  .filter__checkbox {
    align-items: center;
    display: flex;
    margin-bottom: 20px; }
    .filter__checkbox:last-child {
      margin-bottom: 0; }
  .filter__count {
    margin-left: auto; }
  .filter__apply-m {
    margin-top: 20px;
    text-align: center; }

@media (min-width: 840px) {
  .filter__apply-d {
    display: block;
    text-align: center; }
  .filter__apply-m, .filter__title {
    display: none; } }

.filters__selected {
  display: none; }

.filters__m-labels {
  display: flex; }

.filters__desk-labels {
  display: none;
  margin-bottom: 15px;
  margin-right: 15px; }

.filters__content {
  position: relative; }

.filters__container {
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(37, 45, 79, 0.5);
  padding: 20px 20px 12px 12px;
  position: absolute;
  top: 62px;
  width: 320px;
  z-index: 5; }

.filters__mobile-container {
  height: 400px;
  overflow-y: auto;
  padding: 40px 0 0; }

.filters__close-btn {
  align-items: center;
  background-color: #f0f0ed;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  width: 40px; }
  .filters__close-btn .icon-close {
    color: #1b2db5;
    font-size: 1.2rem; }
  .filters__close-btn--mobile {
    right: 0;
    top: 5px; }
  .filters__close-btn--desk {
    right: -20px;
    top: -20px; }

.filters__panel {
  align-items: center;
  border-top: 2px solid #252d4f;
  display: flex;
  height: 62px;
  justify-content: space-between;
  margin-bottom: 22px; }
  .filters__panel.no-top-border {
    border-top: 0;
    position: relative; }

.filters__desk--open .filters__desk-container {
  display: block; }

.filters__desk--open i {
  transform: rotate(270deg); }

.filters__desk--open .filters__link::after {
  opacity: 1; }

.filters__m-btn {
  align-items: center;
  display: flex;
  text-decoration: none; }
  .filters__m-btn i {
    margin-left: 11.5px; }
  .filters__m-btn--applied {
    background-color: #1b2db5;
    color: #fff;
    padding: 8px 12px; }
    .filters__m-btn--applied i {
      filter: invert(100%) brightness(180%); }
  .filters__m-btn--open i {
    transform: rotate(270deg); }

.filters__link {
  align-items: center;
  display: flex;
  position: relative;
  text-decoration: none; }
  .filters__link::after {
    background-color: #252d4f;
    bottom: -3px;
    content: "";
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    width: 100%;
    transition: all 0.2s ease-out; }
  .filters__link:hover::after {
    opacity: 0; }

.filters > article {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 839.98px) {
    .filters > article {
      align-items: center; } }

@media (max-width: 839.98px) {
  .filters__input {
    margin: 0 0 25px 0; }
    .filters__input--desk {
      display: none; } }

.filters__input form {
  display: flex; }
  @media (max-width: 839.98px) {
    .filters__input form {
      justify-content: space-around; } }
  .filters__input form > div {
    max-width: 150px;
    position: relative; }
    @media (max-width: 839.98px) {
      .filters__input form > div {
        max-width: 100%;
        width: 73%; } }
    .filters__input form > div input {
      border: 2px solid rgba(112, 112, 111, 0.4);
      border-radius: 0;
      font-size: 14px;
      height: 27px;
      padding-left: 5px;
      padding-right: 20px;
      width: 100%; }
    .filters__input form > div span {
      align-items: center;
      background: rgba(112, 112, 111, 0.2);
      border-radius: 50%;
      display: flex;
      height: 20px;
      justify-content: center;
      padding: 10px;
      position: absolute;
      right: 5px;
      top: 4px;
      width: 20px; }
      .filters__input form > div span i {
        font-size: 10px; }
  .filters__input form button {
    font-size: 14px;
    margin-left: 5px;
    padding: 3px 10px; }
    @media (max-width: 839.98px) {
      .filters__input form button {
        margin-right: 0; } }

@media (min-width: 840px) {
  .filters__desk-container {
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(37, 45, 79, 0.5);
    display: none;
    left: -30px;
    padding: 0;
    position: absolute;
    top: 30px;
    width: 371px;
    z-index: 5; }
    .filters__desk-container .filter {
      max-height: 434px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 40px 40px 60px 40px; }
  .filters__panel {
    align-items: flex-start;
    height: auto;
    margin-bottom: 40px;
    padding-top: 42px; }
  .filters__selected {
    align-items: center;
    display: flex;
    margin-bottom: -15px;
    margin-top: 30px; }
    .filters__selected-label {
      margin-bottom: 15px;
      margin-right: 36px;
      white-space: nowrap; }
    .filters__selected .icon-close {
      display: block;
      font-size: 1.2rem;
      pointer-events: none; }
    .filters__selected-items {
      display: flex;
      flex-wrap: wrap;
      max-width: 730px; }
    .filters__selected-item {
      align-items: center;
      background-color: #1b2db5;
      color: #fff;
      display: flex;
      margin-bottom: 15px;
      margin-right: 15px;
      padding: 8px 12px; }
      .filters__selected-item:last-child {
        margin-right: 0; }
  .filters__delete-filter {
    cursor: pointer;
    margin-left: 12px; }
  .filters__m-labels {
    display: none; }
  .filters__desk-label {
    margin-right: 35.5px;
    position: relative; }
    .filters__desk-label-wrap {
      align-items: center;
      cursor: pointer;
      display: flex; }
      .filters__desk-label-wrap i {
        margin-left: 11.5px; }
    .filters__desk-label:last-child {
      margin-right: 0; }
  .filters__desk-labels {
    display: flex; }
  .filters__desk-title {
    margin-right: 24px; } }

.sorting-select {
  outline-offset: 5px;
  position: relative;
  white-space: nowrap;
  width: 100%; }
  .sorting-select__label {
    align-items: center;
    color: #252d4f;
    cursor: pointer;
    display: flex;
    font-style: normal;
    font-weight: normal;
    justify-content: center;
    padding-right: 25px;
    position: relative; }
  .sorting-select__dots {
    display: none; }
  .sorting-select__selected-label {
    display: none; }
  .sorting-select__icon {
    position: absolute;
    right: 1px; }
  .sorting-select__list {
    background-color: #fff;
    box-shadow: 0 10px 20px 0 rgba(37, 45, 79, 0.5);
    min-width: 191px;
    padding: 8px 0;
    position: absolute;
    right: -12px;
    text-align: left;
    top: 42px;
    z-index: 2; }
    .sorting-select__list li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      outline-offset: 0;
      padding: 10px 20px; }
      .sorting-select__list li[data-selected] .icon-check {
        display: block; }
      .sorting-select__list li .icon-check {
        font-size: 13px; }
  .sorting-select--active .sorting-select__icon {
    transform: rotate(270deg); }
  .sorting-select .icon-check {
    color: #1b2db5;
    display: none; }

@media (min-width: 840px) {
  .sorting-select__label {
    justify-content: left;
    padding-left: 20px;
    text-align: left; }
  .sorting-select__dots {
    display: inline; }
  .sorting-select__selected-label {
    display: inline;
    margin-left: 24px; }
  .sorting-select__list {
    left: auto;
    text-align: left; }
    .sorting-select__list li {
      border-top: none; }
      .sorting-select__list li:hover {
        background-color: #f0f0ed; }
  .sorting-select--active .sorting-select__label {
    background-color: #fff;
    border-bottom: none;
    color: #252d4f; }
  .sorting-select--active .sorting-select__icon {
    color: #252d4f; } }

.v-card {
  margin-bottom: 40px; }
  .v-card__link {
    color: #1b2db5;
    font-weight: 500;
    text-decoration: none; }
  .v-card__props {
    display: flex;
    flex-wrap: wrap; }
  .v-card__prop {
    color: #252d4f;
    font-size: 1.4rem;
    line-height: 2.4rem; }

@media (min-width: 840px) {
  .v-card {
    margin-bottom: 75px; } }

.newsletter {
  color: #fff;
  margin-left: auto;
  margin-right: auto; }
  .newsletter__wrapper {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 135%;
    min-height: 350px;
    padding: 20px 20px 108px 20px; }
  .newsletter__content {
    max-width: 552px; }
  .newsletter .EPiServerForms section {
    display: flex;
    flex-wrap: wrap; }
  .newsletter .EPiServerForms .Form__Status .Form__Status__Message.hide {
    display: none; }
  .newsletter .EPiServerForms .Form__Element button {
    order: 1; }
  .newsletter .EPiServerForms .Form__Element .Form__Element__Caption {
    display: none; }
  .newsletter .EPiServerForms .Form__Element .FormTextbox {
    flex-grow: 1;
    width: 385px; }
  .newsletter .EPiServerForms .Form__Element .FormChoice,
  .newsletter .EPiServerForms .Form__Element .FormConsentCheckbox {
    font-size: 1.4rem;
    width: 100%; }
    .newsletter .EPiServerForms .Form__Element .FormChoice fieldset,
    .newsletter .EPiServerForms .Form__Element .FormConsentCheckbox fieldset {
      display: flex; }
  .newsletter .EPiServerForms .Form__Element .FormConsentCheckbox p {
    display: inline; }

@media (min-width: 840px) {
  .newsletter__wrapper {
    background-position: right;
    padding: 80px 96px 49px; }
  .newsletter .EPiServerForms .Form__Element button {
    align-self: baseline;
    order: initial;
    padding: 10px 24px; }
  .newsletter .EPiServerForms .Form__Element .FormTextbox {
    margin-right: 24px; } }

@media only screen and (min-width: 1200px) {
  .newsletter__wrapper {
    background-position: right 40px;
    background-size: contain; } }

.cta {
  color: #fff;
  margin-left: auto;
  margin-right: auto; }
  .cta__wrapper {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 135%;
    padding: 20px 20px 120px 20px;
    position: relative;
    width: 100%;
    z-index: 5; }
    .cta__wrapper--image {
      padding-bottom: 40px; }
  .cta__background-wrapper {
    display: flex;
    flex-direction: column;
    position: relative; }
  .cta__close-btn {
    cursor: pointer;
    font-size: 1.2rem;
    margin: 10px 10px 0 auto;
    position: absolute;
    right: 0;
    z-index: 10; }
    .cta__close-btn .icon-round {
      height: 30px;
      line-height: 30px;
      min-width: 30px; }
  .cta__content {
    max-width: 552px; }
  .cta__primary-btn {
    margin-bottom: 20px;
    margin-right: 24px; }
  .cta__text {
    margin-bottom: 20px; }
  .cta__image img {
    clip-path: url("#curve");
    display: block;
    object-fit: cover;
    width: 100%; }
  .cta .link, .cta .mega-menu__secondary-column .cta-block, .mega-menu__secondary-column .cta .cta-block, .cta .mega-menu__preview .cta-block, .mega-menu__preview .cta .cta-block, .cta .feature-mid-copy-block .cta-block, .feature-mid-copy-block .cta .cta-block, .cta .feature-image-text-heading-cta-block .cta-block, .feature-image-text-heading-cta-block .cta .cta-block {
    white-space: nowrap; }
  .cta__form .cta__wrapper {
    background-position: left bottom; }
  .cta .white-form .FormConsentCheckbox a {
    color: #252d4f; }
    .cta .white-form .FormConsentCheckbox a:hover {
      color: #252d4f; }
  .cta .white-form .EPiServerForms a {
    border-color: #1b2db5;
    color: #1b2db5; }
    .cta .white-form .EPiServerForms a:hover {
      background-color: #1b2db5;
      color: #fff; }
  .cta .EPiServerForms .FormConsentCheckbox {
    margin-top: 10px; }
    .cta .EPiServerForms .FormConsentCheckbox a {
      border: none;
      display: inline;
      font-size: 1.4rem;
      font-weight: normal;
      padding: 0;
      text-decoration: underline; }
      .cta .EPiServerForms .FormConsentCheckbox a:hover {
        background-color: transparent;
        color: #fff;
        text-decoration: none; }
  .cta .EPiServerForms section {
    display: flex;
    flex-wrap: wrap; }
  .cta .EPiServerForms .Form__Status .Form__Status__Message.hide {
    display: none; }
  .cta .EPiServerForms .Form__Element {
    margin-bottom: 10px; }
    .cta .EPiServerForms .Form__Element button {
      order: 1; }
    .cta .EPiServerForms .Form__Element .FormTextbox {
      flex-grow: 1;
      max-width: 500px;
      width: 100%; }
    .cta .EPiServerForms .Form__Element .FormChoice,
    .cta .EPiServerForms .Form__Element .FormConsentCheckbox {
      font-size: 1.4rem;
      width: 100%; }
      .cta .EPiServerForms .Form__Element .FormChoice fieldset,
      .cta .EPiServerForms .Form__Element .FormConsentCheckbox fieldset {
        display: flex; }
    .cta .EPiServerForms .Form__Element .FormConsentCheckbox p {
      display: inline; }
  .cta__modal-dialog {
    margin-left: auto;
    margin-right: auto;
    max-height: 650px;
    overflow: auto;
    position: relative; }
  .cta__step-2 {
    align-items: center;
    background-color: rgba(37, 45, 79, 0.8);
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 30; }
    .cta__step-2--open {
      display: flex; }
  .cta__download-image {
    display: flex;
    justify-content: center; }
    .cta__download-image img {
      display: block;
      width: 200px; }
  .cta__desk-bg, .cta__m-bg {
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0; }
  .cta__desk-bg {
    display: none; }

@media (min-width: 840px) {
  .cta__wrapper {
    background-position: right;
    padding: 80px calc(min(9vw, 96px)) 49px; }
    .cta__wrapper--image {
      flex: 1;
      max-width: 640px;
      padding-right: 40px; }
  .cta__text {
    margin-bottom: 40px; }
  .cta__background-wrapper {
    flex-direction: row; }
  .cta .EPiServerForms .Form__Element button {
    align-self: baseline;
    order: initial; }
  .cta .EPiServerForms .Form__Element .FormTextbox {
    margin-right: 24px; }
  .cta .EPiServerForms .FormConsentCheckbox {
    margin-top: 30px; }
  .cta__step-2 .cta__wrapper, .cta__form .cta__wrapper {
    display: flex;
    justify-content: space-between; }
  .cta__step-2 .cta__content, .cta__form .cta__content {
    max-width: 466px;
    padding-right: 10px; }
  .cta__step-2 .cta__modal-form, .cta__step-2 .cta__form-fields, .cta__form .cta__modal-form, .cta__form .cta__form-fields {
    width: 384px; }
  .cta__download-image {
    align-self: flex-end;
    padding-right: calc(min(9vw, 96px)); }
  .cta__download .cta__background-wrapper {
    flex-direction: row; }
  .cta__desk-bg {
    display: block; }
  .cta__m-bg {
    display: none; }
  .cta__image {
    flex: 1; }
    .cta__image img {
      clip-path: url("#curve-desk");
      height: 100%; } }

@media only screen and (min-width: 1200px) {
  .cta__wrapper {
    background-position: right 40px;
    background-size: contain; } }

.cta-image-block > div {
  display: flex;
  flex-wrap: wrap; }
  .cta-image-block > div > div {
    align-items: flex-end;
    display: flex;
    flex: 1 0 25%;
    justify-content: center;
    margin: 15px 0; }
    @media only screen and (max-width: 768px) {
      .cta-image-block > div > div {
        flex: 1 0 100%; } }

.cta-image-block__image-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 10px; }
  .cta-image-block__image-content a {
    display: block;
    text-align: center;
    text-decoration: none; }
    .cta-image-block__image-content a img {
      width: 100%; }

.page-details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px; }
  .page-details__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: -10px;
    margin-top: 20px; }
    .page-details__col:only-child {
      flex-basis: auto;
      flex-direction: row; }
    .page-details__col > div {
      margin-bottom: 10px;
      margin-right: 12px; }
    .page-details__col span {
      white-space: nowrap; }
  .page-details__row {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px; }
    .page-details__row .page-details__col {
      margin-top: 0; }
  .page-details__title {
    font-weight: 600;
    white-space: nowrap; }
  .page-details__reading-time {
    align-items: center;
    display: flex; }
    .page-details__reading-time i {
      background-size: contain;
      height: 20px;
      margin-right: 10px;
      width: 20px; }
  .page-details__author-image {
    background-position: center center;
    background-size: cover;
    border-radius: 36px;
    flex-shrink: 0;
    height: 72px;
    margin-right: 16px;
    width: 72px; }
    .page-details__author-image--big {
      border-radius: 84px;
      height: 168px;
      width: 168px; }
  .page-details__author-full-name {
    white-space: normal; }
  .page-details__author-bio {
    margin-top: 40px; }
  .page-details__author-info__title {
    margin-bottom: 20px; }
  .page-details__author-links {
    color: #1b2db5;
    display: flex;
    font-size: 1.8rem;
    position: static; }
    .page-details__author-links .icon-round {
      align-items: center;
      display: flex;
      justify-content: center; }
  .page-details__author--large .page-details__author-job {
    margin-bottom: 0 !important; }
  .page-details__location {
    align-items: center;
    display: flex; }
    .page-details__location i {
      margin-right: 8px; }

@media (max-width: 839.98px) {
  .page-details__col {
    flex-basis: 50%;
    margin-right: -12px; }
  .page-details__author {
    flex-basis: auto; }
    .page-details__author--large {
      align-items: flex-start;
      flex-direction: column; }
      .page-details__author--large .page-details__author-image {
        margin-bottom: 20px; }
      .page-details__author--large .page-details__author-links {
        margin-top: 20px; }
    .page-details__author-bio {
      margin-top: 20px; }
    .page-details__author .page-details__col {
      flex-basis: auto; } }

@media (min-width: 840px) {
  .page-details {
    flex-wrap: nowrap; }
    .page-details > div:not(:last-of-type) {
      margin-right: calc(10% - 12px); }
    .page-details__col:first-child:nth-last-child(2),
    .page-details__col:first-child:nth-last-child(2) ~ .page-details__col {
      align-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start; }
    .page-details__author-image {
      margin-right: 24px; }
    .page-details__author-links {
      position: absolute;
      top: calc(100% + 15px); }
    .page-details__author-info__title {
      margin-bottom: 40px; }
    .page-details__author .page-details__col {
      position: relative; } }

.insight-page__title {
  margin-bottom: 20px; }

.insight-page__primary {
  display: flex;
  justify-content: center; }
  .insight-page__primary .card {
    border-bottom: none;
    margin-bottom: 30px;
    margin-right: 0;
    max-width: 500px;
    width: auto; }
    .insight-page__primary .card__link {
      padding-bottom: 0; }
    .insight-page__primary .card__title {
      font-size: 2rem;
      -webkit-line-clamp: initial;
      line-clamp: initial; }
    .insight-page__primary .card__type-label {
      left: 16px;
      top: 16px; }
    .insight-page__primary .card__img {
      display: block;
      height: auto;
      max-width: 100%; }
      .insight-page__primary .card__img-wrap {
        max-width: 500px; }

.insight-page__secondary {
  display: none; }

@media (min-width: 840px) {
  .insight-page__title {
    margin-bottom: 50px; }
  .insight-page__primary {
    display: block; }
    .insight-page__primary .card {
      border-bottom: none;
      margin-bottom: 40px;
      margin-right: 0;
      max-width: 100%;
      width: 100%; }
      .insight-page__primary .card__type-label {
        left: 16px;
        top: 16px; }
      .insight-page__primary .card__link {
        flex-direction: row;
        padding-bottom: 0;
        width: 100%; }
      .insight-page__primary .card__desc {
        flex-grow: initial;
        flex-shrink: 2;
        justify-content: flex-end;
        min-width: 260px;
        padding: 0 0 40px 0;
        width: 100%; }
      .insight-page__primary .card__title, .insight-page__primary .card__read-time, .insight-page__primary .card__infographic, .insight-page__primary .card__card, .insight-page__primary .card__video, .insight-page__primary .card__webinar, .insight-page__primary .card__event, .insight-page__primary .card__link-text {
        padding: 0 40px; }
      .insight-page__primary .card__title {
        flex-grow: initial;
        font-size: 2.4rem; }
      .insight-page__primary .card__img {
        display: block;
        height: auto;
        max-width: 100%;
        width: auto; }
        .insight-page__primary .card__img-wrap {
          margin-bottom: 0;
          max-width: 744px; }
  .insight-page__secondary {
    display: flex;
    justify-content: center; }
    .insight-page__secondary .card {
      margin-bottom: 40px;
      margin-right: 24px;
      max-width: 552px;
      width: auto; }
      .insight-page__secondary .card:last-child {
        margin-right: 0; }
      .insight-page__secondary .card__type-label {
        left: 16px;
        top: 16px; }
      .insight-page__secondary .card__title {
        font-size: 2.4rem;
        -webkit-line-clamp: initial;
        line-clamp: initial; }
      .insight-page__secondary .card__desc {
        padding: 0 20px; }
      .insight-page__secondary .card__link {
        padding-bottom: 40px; }
      .insight-page__secondary .card__img {
        display: block;
        height: auto;
        max-width: 100%;
        width: auto; }
        .insight-page__secondary .card__img-wrap {
          margin-bottom: 40px;
          max-width: 552px; } }

.vacancy-page__hero {
  margin-bottom: 36px; }

.vacancy-page .filters__panel {
  margin-bottom: 17px; }

@media (min-width: 840px) {
  .vacancy-page .filters__panel {
    margin-bottom: 60px; } }

.job-page__content {
  padding: 15px 0 0 0; }

.job-page__props {
  display: flex;
  margin-bottom: 75px; }
  .job-page__props-left, .job-page__props-right {
    width: 50%; }

.job-page__desc {
  margin-bottom: 68px; }
  .job-page__desc ol,
  .job-page__desc ul {
    display: block;
    list-style-type: disc;
    margin: 1em 0;
    padding-left: 40px; }

.job-page__link {
  display: flex;
  justify-content: center; }

@media (min-width: 840px) {
  .job-page__content {
    padding: 80px 0 0 0; } }

.search__bar {
  background-color: #f0f0ed;
  padding: 40px 0; }
  .search__bar .row {
    position: relative; }

.search__row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.search__input {
  border: 1px solid #575756;
  height: 40px;
  line-height: 16px;
  margin-right: 24px;
  padding: 0 12px;
  width: 100%; }

.search__btn-close {
  cursor: pointer;
  display: none;
  font-size: 12px;
  position: absolute;
  right: 0; }

.search__btn-submit i {
  display: none; }

.search__results-header {
  margin: 40px auto 80px; }
  .search__results-header .search__row {
    margin-bottom: -20px; }

.search__results-number {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 20px;
  margin-right: 20px; }

.search__results-body {
  margin-bottom: 60px; }
  .search__results-body .search__results-item:not(:last-of-type) {
    border-bottom: 1px solid #dedede;
    margin-bottom: 25px;
    padding-bottom: 25px; }

.search__results-item-title {
  color: #1b2db5;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  text-decoration: none; }

.search__results-item-tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 15px;
  width: 100%; }
  .search__results-item-tags b {
    margin-bottom: 10px; }
  .search__results-item-tags aside {
    display: flex;
    flex: 1 0 90%;
    flex-wrap: wrap; }
    .search__results-item-tags aside p {
      background: #eee;
      border: 1px solid #d9d9d9;
      border-radius: 20px;
      color: #313131;
      font-weight: 500;
      margin-bottom: 10px;
      margin-left: 10px;
      padding: 3px 18px; }

.search__results-item-description {
  margin-top: 10px; }

.search__results-item-date {
  color: #989898;
  font-size: 13px;
  margin-top: 15px; }

.search__results-footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px; }
  .search__results-footer .search__results-number {
    margin-bottom: 0; }

.search__sort {
  margin-bottom: 20px;
  margin-left: auto;
  width: auto; }
  .search__sort-dropdown {
    display: none; }
    .search__sort-dropdown--open {
      display: block; }
  .search__sort-option--active .icon-check {
    display: block; }

@media (max-width: 839.98px) {
  .search__bar {
    background-color: #f0f0ed; }
  .search__input {
    margin-right: 12px;
    min-width: auto;
    width: 100%; }
  .search__btn {
    padding: 10px 16px; }
    .search__btn-close {
      display: none;
      padding-right: 8px; }
    .search__btn-submit i {
      display: block;
      margin-left: 0; } }

.error__container {
  margin: 20px auto 80px; }

.error__col-image {
  align-items: center;
  display: flex;
  justify-content: center; }

.error__col-info {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .error__col-info .search__input {
    min-width: auto;
    width: 360px; }
  .error__col-info .search__btn-submit i {
    display: block; }

.error__col-number {
  color: #283583;
  font-size: 160px;
  font-weight: 700;
  line-height: 200px;
  text-align: center; }

.error__row {
  display: flex;
  justify-content: center;
  margin-top: 40px; }

.error__image {
  max-width: 550px;
  width: 100%; }

.error__search-message {
  margin: 40px 0 20px; }

.error__help {
  margin-top: 40px; }

@media (max-width: 839.98px) {
  .error__col-image, .error__col-number {
    margin-bottom: 40px; }
  .error__col-number {
    font-size: 120px;
    line-height: 140px; }
  .error__col-info .search__btn-close {
    display: none; } }

@media (min-width: 840px) {
  .error__container {
    margin: 80px auto 120px; }
  .error__col-info {
    order: 1; }
  .error__col-image, .error__col-number {
    order: 2; } }
