// styles for elements

h1,
.h1-article,
h2,
h3,
h4,
h5 {
  color: $color-dark;
  font-family: $font-name-primary;
  letter-spacing: 0;
}

h1 {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 4.2rem;
}

.h1-article {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.2rem;
}

h2 {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

h3 {
  font-size: 2rem;
  line-height: 2.8rem;
}

h4 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

h5 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.text {
  font-family: $font-name-primary;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.text--cut {
  @include text--cut;
}

.link {
  color: $color-link-blue;
  cursor: pointer;
  font-family: $font-name-primary;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.6rem;
  position: relative;
  text-decoration: none;

  &::after {
    background-color: $color-link-blue;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::before {
    background-color: $color-white;
    bottom: -1px;
    content: "";
    height: 2px;
    left: -10px;
    opacity: 0;
    position: absolute;
    width: 10px;
    z-index: 5;
  }

  &:hover {
    @include link-animation-v2();
  }

  &--white {
    color: $color-white;

    &::after {
      background-color: $color-white;
    }
  }
}

.icon-round {
  border-radius: 20px;
  display: block;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  text-align: center;

  &:hover {
    background-color: $color-grey-light;
  }
}

.btn {
  align-items: center;
  border: 2px solid;
  color: $color-link-blue;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  font-family: $font-name-primary;
  font-size: 1.6rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.6rem;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;

  &--icon-right {
    i {
      margin-left: 12px;
    }
  }

  i {
    color: $color-link-blue;

    &.icon-download,
    &.icon-external {
      background-color: $color-link-blue;
    }

    @include transition();
  }

  @include transition();

  &:hover {
    background-color: $color-link-blue;
    border: 2px solid $color-link-blue;
    color: $color-white;

    i {
      color: $color-white;

      &.icon-download,
      &.icon-external {
        background-color: $color-white;
      }
    }
  }

  &[disabled] {
    background-color: $color-white;
    border-color: $color-disabled;
    color: $color-disabled;
    opacity: 0.4;
    pointer-events: none;

    i {
      color: $color-disabled;

      &.icon-download,
      &.icon-external {
        background-color: $color-disabled;
      }
    }
  }

  &--white {
    background-color: transparent;
    border-color: $color-white;
    color: $color-white;
    mix-blend-mode: screen;

    i {
      color: $color-white;

      &.icon-download,
      &.icon-external {
        background-color: $color-white;
      }
    }

    &:hover {
      background-color: $color-white;
      border: 2px solid $color-white;
      color: $color-black;

      i {
        color: $color-black;

        &.icon-download,
        &.icon-external {
          background-color: $color-black;
        }
      }
    }
  }

  &--white-bg {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-link-blue;

    i {
      color: $color-link-blue;
    }

    &:hover {
      background-color: transparent;
      border-color: $color-white;
      color: $color-white;

      i {
        color: $color-white;
      }
    }
  }

  &--cta {
    padding: 14px 24px;
  }

  &--holder {
    text-align: center;
  }
}

.lock-scroll {
  overflow: hidden;
}

@include media-breakpoint-up(md) {
  h1 {
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 5.8rem;
  }

  .h1-article {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 3.8rem;
  }

  h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  h4 {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  h5 {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
