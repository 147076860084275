.newsletter {
  color: $color-white;
  margin-left: auto;
  margin-right: auto;

  &__wrapper {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 135%;
    min-height: 350px;
    padding: 20px 20px 108px 20px;
  }

  &__content {
    max-width: 552px;
  }

  .EPiServerForms {
    section {
      display: flex;
      flex-wrap: wrap;
    }

    .Form__Status {
      .Form__Status__Message {
        &.hide {
          display: none;
        }
      }
    }

    .Form__Element {
      button {
        order: 1;

        @extend .btn;
        @extend .btn--cta;
      }

      input[type="checkbox"] {
        @extend .checkbox;
      }

      input[type="text"] {
        @extend .text-box;
      }

      .Form__Element__Caption {
        display: none;
      }

      .FormTextbox {
        flex-grow: 1;
        width: 385px;
      }

      .FormChoice,
      .FormConsentCheckbox {
        font-size: 1.4rem;
        width: 100%;

        fieldset {
          display: flex;
        }
      }

      .FormConsentCheckbox {
        p {
          display: inline;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .newsletter {
    &__wrapper {
      background-position: right;
      padding: 80px 96px 49px;
    }

    .EPiServerForms {
      .Form__Element {
        button {
          align-self: baseline;
          order: initial;
          padding: 10px 24px;
        }

        .FormTextbox {
          margin-right: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .newsletter {
    &__wrapper {
      background-position: right 40px;
      background-size: contain;
    }
  }
}
