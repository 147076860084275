.awards-strip-block {
  &__row {
    justify-content: center;
  }

  &__col {
    align-items: center;
    display: flex;
    height: 168px;
    justify-content: center;
  }

  &__logo {
    max-height: 72px;
    max-width: 72px;
    object-fit: scale-down;
  }
}

@include media-breakpoint-down(md) {
  .awards-strip-block {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -40px;
    }

    &__col {
      height: 72px;
      margin-bottom: 40px;
      width: 112px;
    }
  }
}

@include media-breakpoint-up(md) {
  .awards-strip-block {
    &__col {
      @extend .col-md-2;
    }

    &__logo {
      max-height: 122px;
      max-width: 115px;
    }
  }
}
