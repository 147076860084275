.cards {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 360px;

    & .card__title {
      flex-grow: 0;
    }

    & .card__prop {
      margin-top: auto;
    }
  }

  &--one-result {
    .card {
      margin-right: 0;
      max-width: 336px;

      &__img-wrap {
        max-width: 336px;
      }

      .card__img {
        height: 210px;
      }
    }
  }

  &--two-results {
    .card {
      margin-right: 0;
      max-width: 176px;
      padding-right: 16px;
      width: 176px;

      @extend .card--small-spec-prop;

      &:last-child {
        margin-right: 0;
        max-width: 160px;
        padding-right: 0;
        width: auto;
      }

      &__img-wrap {
        max-width: 176px;
      }
    }
  }

  &--three-results {
    .card {
      margin-right: 0;
      max-width: 176px;
      padding-right: 16px;
      width: 176px;

      &:nth-child(1),
      &:nth-child(2) {
        @extend .card--small-spec-prop;
      }

      &:nth-child(2) {
        margin-right: 0;
        max-width: 160px;
        padding-right: 0;
        width: auto;
      }

      &__img-wrap {
        max-width: 176px;
      }

      &:nth-child(3) {
        margin-right: 0;
        max-width: 336px;
        padding-right: 0;
        width: auto;

        .card__img-wrap {
          max-width: 336px;
        }

        .card__img {
          height: 210px;
        }
      }
    }
  }

  &--four-results {
    .card {
      margin-right: 0;
      max-width: 176px;
      padding-right: 16px;
      width: 176px;

      @extend .card--small-spec-prop;

      &:nth-child(2n) {
        margin-right: 0;
        max-width: 160px;
        padding-right: 0;
        width: auto;
      }

      &__img-wrap {
        max-width: 176px;
      }
    }
  }

  &--five-results {
    .card {
      margin-right: 0;
      max-width: 176px;
      padding-right: 16px;
      width: 176px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @extend .card--small-spec-prop;
      }

      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 0;
        max-width: 160px;
        padding-right: 0;
        width: auto;
      }

      &__img-wrap {
        max-width: 176px;
      }

      &:nth-child(5) {
        margin-right: 0;
        max-width: 336px;
        padding-right: 0;
        width: auto;

        .card__img-wrap {
          max-width: 336px;
        }

        .card__img {
          height: 210px;
        }
      }
    }
  }

  &--more-five-results {
    .card {
      margin-right: 0;
      max-width: initial;
      padding-right: 16px;
      width: 176px;

      &__img-wrap {
        max-width: initial;
        width: 160px;
      }

      &:nth-child(6n + 1),
      &:nth-child(6n + 2),
      &:nth-child(6n + 3),
      &:nth-child(6n + 4) {
        @extend .card--small-spec-prop;
      }

      &:nth-child(6n + 2),
      &:nth-child(6n + 4) {
        margin-right: 0;
        padding-right: 0;
        width: 160px;
      }

      &:nth-child(6n + 3) {
        width: 176px;

        .card__img-wrap {
          width: 160px;
        }
      }

      &:nth-child(6n + 5) {
        margin-right: 0;
        padding-right: 0;
        width: 336px;

        .card__img-wrap {
          width: 336px;
        }

        .card__img {
          height: 210px;
        }
      }

      &:nth-child(6n) {
        margin-right: 0;
        padding-right: 0;
        width: 336px;

        .card__img-wrap {
          display: none;
        }

        .card__type-label {
          align-self: flex-start;
          margin-bottom: 16px;
          margin-left: 0;
          position: initial;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .cards {
    &__container {
      justify-content: flex-start;
      max-width: initial;
    }
  }
}

@media only screen and (max-width: 362px) {
  .cards {
    &--more-five-results {
      .card {
        width: 50%;

        &:nth-child(6n + 5) {
          width: 100%;
        }

        &:nth-child(6n + 3) {
          width: 50%;
        }

        &:nth-child(6n + 2),
        &:nth-child(6n + 4) {
          width: 50%;
        }

        .card__img-wrap {
          width: 100% !important;
        }
      }
    }
  }
}
