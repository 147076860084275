.icon-grid-card-block,
.stats-grid-card-block,
.icon-grid-card-block__link {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  text-align: center;

  &__link {
    line-height: 24px;
    margin-bottom: 0;
    text-decoration: none;

    &:hover {
      background-color: $color-link-blue-hover;
    }
  }
}

.icon-grid-card-block {
  &__img {
    display: block;
    width: 72px;
  }

  &__title {
    font-weight: 500;
    margin-top: 20px;
  }

  &__description {
    margin-top: 10px;
  }
}

.stats-grid-card-block {
  &__stat {
    font-size: 32px;
    line-height: 38px;
  }
}

.icon-text-grid-block {
  &__title,
  &__impact {
    margin-bottom: 20px;
  }

  &__row {
    margin-bottom: -40px;
  }
}

@include media-breakpoint-up(md) {
  .icon-text-grid-block {
    &__impact {
      margin-bottom: 40px;
    }
  }

  .stats-grid-card-block {
    &__title {
      margin-top: 10px;
    }
  }
}
