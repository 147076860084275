.message {
  background: rgba(48, 154, 242, 0.16);
  color: #309af2;
  font-size: 1.2rem;
  margin-bottom: 15px;
  padding: 5px 10px;
}

.backend {
  &__exception-wrapper {
    margin-bottom: 30px;
    position: relative;

    &--methods {
      margin-top: 15px;

      @include media-breakpoint-up(md) {
        margin-top: 30px;
      }
    }
  }

  &__exception {
    display: block;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.58;
    padding: 10px 4px 12px 45px;
    white-space: initial;
  }
}
