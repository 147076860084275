.hero {
  position: relative;

  &.small,
  &.medium,
  &.large {
    margin-top: 0;
  }

  &--primary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
  }

  &--narrow {
    .hero__container {
      margin-top: -40px;
      padding-left: $container-padding-m;
      padding-right: $container-padding-m;
      position: initial;
    }
  }

  &--job {
    .hero {
      &__title {
        font-size: 3.6rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 4.2rem;
      }
    }
  }

  &__job-btn {
    display: flex;
    justify-content: flex-start;
    margin-top: 59px;
    width: 100%;
  }

  &__bg {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 0;

    &-image {
      background-position: center;
      background-size: cover;
      width: 100%;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__block {
    background-color: $color-blue;
    color: $color-white;
    padding: 40px;
    position: relative;

    &-content {
      align-items: center;
      display: flex;
    }
  }

  &__title {
    color: $color-white;
    margin-bottom: 20px;
  }

  &__description {
    font-weight: 300;
    margin: 0;
  }

  & .cta-block {
    @extend .btn;
    @extend .btn--cta;
    @extend .btn--white;

    flex-shrink: 0;
  }
}

@include media-breakpoint-up(md) {
  .hero {
    &--primary {
      height: 600px;

      .hero__block {
        bottom: 40px;

        &-content > div:last-child {
          margin-right: 0;
        }
      }

      &.hero--narrow {
        height: 564px;
      }
    }

    &--narrow {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
      padding-left: $container-padding-d;
      padding-right: $container-padding-d;

      &.hero--right {
        .hero__container {
          left: initial;
          right: 132px;
        }
      }

      .hero__bg {
        position: initial;
      }

      .hero__container {
        left: 132px;
        margin-top: 0;
        padding: 0;
        position: absolute;
      }

      h1 {
        font-size: 3.2rem;
        line-height: 4.2rem;
      }
    }

    &--secondary {
      height: 525px;
      overflow: hidden;
    }

    &--job {
      .hero {
        &__title {
          font-size: 3.2rem;
          line-height: 3.8rem;
        }
      }
    }

    &__job-btn {
      justify-content: flex-end;
    }

    &__bg {
      &--mobile {
        display: none;
      }

      &-image {
        height: 525px;
      }
    }

    &__block {
      &-wrapper--secondary {
        transform: translateY(-100%);
      }
    }

    &__title {
      &--primary {
        font-size: 4.8rem;
        line-height: 5.8rem;
      }

      &--secondary {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    }

    &__description {
      margin-right: 40px;
    }

    & .cta-block {
      margin-left: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  .hero {
    &__bg {
      height: 360px;
      position: static !important;

      &--mobile {
        margin-top: -360px;
      }

      &-image {
        height: 225px;
      }
    }

    &__container {
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
    }

    &__block {
      padding: 20px;

      &-wrapper {
        &--secondary {
          margin: -40px 4px 0;
        }
      }

      &-content {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    &__title {
      &--primary {
        font-size: 3.6rem;
        line-height: 4.2rem;
      }

      &--secondary {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    & .cta-block {
      margin: 20px 0 0 0;
    }
  }
}
