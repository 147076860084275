.card {
  border-bottom: 2px solid $color-grey-light;
  display: flex;
  margin-bottom: 40px;
  margin-right: 16px;
  max-width: 160px;
  position: relative;
  width: auto;

  &__title,
  &__read-time,
  &__infographic,
  &__video,
  &__webinar,
  &__event,
  &__whitepaper,
  &__recwebinar,
  &__factsheet {
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    flex-grow: 1;

    @include text--cut(3);
  }

  &__webinar {
    flex-wrap: wrap;
  }

  &__img-wrap {
    margin-bottom: 20px;
    max-width: 160px;
  }

  &__img {
    display: block;
    height: 100px;
    max-width: 100%;
    width: 100%;
  }

  &__time,
  &__read-time,
  &__infographic,
  &__video,
  &__webinar,
  &__event,
  &__whitepaper,
  &__recwebinar,
  &__factsheet {
    i {
      margin-right: 8px;
    }
  }

  &--small-spec-prop {
    .card__prop {
      font-size: 1.4rem;

      i {
        background-size: contain;
        margin-right: 6px;
      }
    }

    .card__read-time,
    .card__video,
    .card__whitepaper,
    .card__factsheet,
    .card__infographic {
      i {
        height: 14px;
        width: 14px;
      }
    }

    .card__recwebinar,
    .card__event,
    .card__webinar {
      i {
        height: 19px;
        width: 19px;
      }
    }

    .icon-location {
      flex-shrink: 0;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__event-time {
    margin-right: 25px;
  }

  &__link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    outline-offset: -1px;
    padding-bottom: 40px;
    text-decoration: none;
    width: 100%;

    &:hover {
      .card__type-label {
        background-color: $color-link-blue;
        color: $color-white;
      }
    }
  }

  &__type-label {
    background-color: $color-grey-light;
    font-size: 1.4rem;
    font-weight: 500;
    left: 8px;
    letter-spacing: 0;
    line-height: 2.4rem;
    padding: 0 8px;
    position: absolute;
    top: 8px;
    white-space: nowrap;
    width: initial;

    &--no-img {
      margin-bottom: 16px;
      margin-left: 20px;
      position: initial;
    }
  }
}

.pagination {
  margin-bottom: 40px;
  text-align: center;
}

@include media-breakpoint-up(md) {
  .card {
    border-bottom: none;
    margin-bottom: 46px;
    margin-right: 24px;
    max-width: 264px;
    width: 264px;

    &__title,
    &__read-time,
    &__infographic,
    &__video,
    &__webinar,
    &__event,
    &__whitepaper,
    &__recwebinar,
    &__factsheet {
      padding: 0 20px;
    }

    &__link {
      padding-bottom: 24px;

      &-text {
        padding: 0 20px;
      }

      &:hover {
        background-color: $color-link-blue-hover;
      }
    }

    &__img-wrap {
      max-width: 264px;
    }

    &__img {
      height: 165px;
      width: 264px;
    }
  }
}

//only for margin
@media only screen and (min-width: 1200px) {
  .card {
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}
