.expertise-grid-block {
  &__links {
    display: flex;
    flex-direction: column;
  }

  &__link {
    align-items: center;
    background-color: $color-white;
    color: $color-link-blue;
    display: flex;
    min-height: 64px;
    outline-offset: -2px;
    padding-left: 64px;
    padding-right: 5px;
    position: relative;
    text-decoration: none;

    &::before {
      background-color: $color-white;
      border: 2px solid $color-link-blue;
      border-radius: 100%;
      content: "";
      height: 12px;
      left: 25px;
      position: absolute;
      width: 12px;
    }

    &:hover {
      background-color: $color-link-blue;
      color: $color-white;

      &::before {
        background-color: $color-link-blue;
        border-color: $color-white;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .expertise-grid-block {
    &__links {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__link {
      width: 360px;
    }
  }
}
