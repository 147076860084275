.job-page {
  &__content {
    padding: 15px 0 0 0;
  }

  &__props {
    display: flex;
    margin-bottom: 75px;

    &-left,
    &-right {
      width: 50%;
    }
  }

  &__desc {
    ol,
    ul {
      display: block;
      list-style-type: disc;
      margin: 1em 0;
      padding-left: 40px;
    }

    margin-bottom: 68px;
  }

  &__link {
    display: flex;
    justify-content: center;
  }
}

@include media-breakpoint-up(md) {
  .job-page {
    &__content {
      padding: 80px 0 0 0;
    }
  }
}
