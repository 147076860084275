.filters {
  &__selected {
    display: none;
  }

  &__m-labels {
    display: flex;
  }

  &__desk-labels {
    display: none;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  &__content {
    position: relative;
  }

  &__container {
    background-color: $color-white;
    box-shadow: 0 10px 20px 0 rgba(37, 45, 79, 0.5);
    padding: 20px 20px 12px 12px;
    position: absolute;
    top: 62px;
    width: 320px;
    z-index: 5;
  }

  &__mobile-container {
    height: 400px;
    overflow-y: auto;
    padding: 40px 0 0;
  }

  &__close-btn {
    align-items: center;
    background-color: $color-grey-light;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    width: 40px;

    .icon-close {
      color: $color-link-blue;
      font-size: 1.2rem;
    }

    &--mobile {
      right: 0;
      top: 5px;
    }

    &--desk {
      right: -20px;
      top: -20px;
    }
  }

  &__panel {
    align-items: center;
    border-top: 2px solid $color-dark;
    display: flex;
    height: 62px;
    justify-content: space-between;
    margin-bottom: 22px;

    &.no-top-border {
      border-top: 0;
      position: relative;
    }
  }

  &__desk--open {
    .filters__desk-container {
      display: block;
    }

    i {
      transform: rotate(270deg);
    }

    .filters__link {
      &::after {
        opacity: 1;
      }
    }
  }

  &__m-btn {
    align-items: center;
    display: flex;
    text-decoration: none;

    i {
      margin-left: 11.5px;
    }

    &--applied {
      background-color: $color-link-blue;
      color: $color-white;
      padding: 8px 12px;

      i {
        filter: invert(100%) brightness(180%); // white
      }
    }

    &--open {
      i {
        transform: rotate(270deg);
      }
    }
  }

  &__link {
    align-items: center;
    display: flex;
    position: relative;
    text-decoration: none;

    &::after {
      background-color: $color-dark;
      bottom: -3px;
      content: "";
      height: 2px;
      left: 0;
      opacity: 1;
      position: absolute;
      width: 100%;

      @include transition();
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }

  > article {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }

  &__input {
    @include media-breakpoint-down(md) {
      margin: 0 0 25px 0;

      &--desk {
        display: none;
      }
    }

    form {
      display: flex;

      @include media-breakpoint-down(md) {
        justify-content: space-around;
      }

      > div {
        max-width: 150px;
        position: relative;

        @include media-breakpoint-down(md) {
          max-width: 100%;
          width: 73%;
        }

        input {
          border: 2px solid rgba($color-grey-medium, 0.4);
          border-radius: 0;
          font-size: 14px;
          height: 27px;
          padding-left: 5px;
          padding-right: 20px;
          width: 100%;
        }

        span {
          align-items: center;
          background: rgba($color-grey-medium, 0.2);
          border-radius: 50%;
          display: flex;
          height: 20px;
          justify-content: center;
          padding: 10px;
          position: absolute;
          right: 5px;
          top: 4px;
          width: 20px;

          i {
            font-size: 10px;
          }
        }
      }

      button {
        font-size: 14px;
        margin-left: 5px;
        padding: 3px 10px;

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .filters {
    &__desk-container {
      background-color: $color-white;
      box-shadow: 0 10px 20px 0 rgba(37, 45, 79, 0.5);
      display: none;
      left: -30px;
      padding: 0;
      position: absolute;
      top: 30px;
      width: 371px;
      z-index: 5;

      .filter {
        max-height: 434px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 40px 40px 60px 40px;
      }
    }

    &__panel {
      align-items: flex-start;
      height: auto;
      margin-bottom: 40px;
      padding-top: 42px;
    }

    &__selected {
      align-items: center;
      display: flex;
      margin-bottom: -15px;
      margin-top: 30px;

      &-label {
        margin-bottom: 15px;
        margin-right: 36px;
        white-space: nowrap;
      }

      .icon-close {
        display: block;
        font-size: 1.2rem;
        pointer-events: none;
      }

      &-items {
        display: flex;
        flex-wrap: wrap;
        max-width: 730px;
      }

      &-item {
        align-items: center;
        background-color: $color-link-blue;
        color: $color-white;
        display: flex;
        margin-bottom: 15px;
        margin-right: 15px;
        padding: 8px 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__delete-filter {
      cursor: pointer;
      margin-left: 12px;
    }

    &__m-labels {
      display: none;
    }

    &__desk {
      &-label {
        margin-right: 35.5px;
        position: relative;

        &-wrap {
          align-items: center;
          cursor: pointer;
          display: flex;

          i {
            margin-left: 11.5px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &-labels {
        display: flex;
      }

      &-title {
        margin-right: 24px;
      }
    }
  }
}
