.image-text-block {
  overflow: hidden;

  &__image {
    display: block;
    width: 100%;
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}

@include media-breakpoint-up(md) {
  .image-text-block {
    &--right {
      & .row {
        flex-direction: row-reverse;
      }
    }

    &__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .image-text-block {
    $this: &;

    &--heading {
      .row {
        flex-direction: column-reverse;
      }

      .image-text-block__text {
        margin-bottom: 20px;
      }
    }

    &__image {
      #{$this}:not(#{$this}--heading) & {
        margin-bottom: 20px;
      }
    }

    &__title {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 20px;
    }
  }
}
