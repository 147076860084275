.pagination {
  &-holder {
    color: $color-link-blue;
    display: flex;
    margin-right: -4px;
    margin-top: 40px;

    .pagination-btn {
      font-weight: 500;
      margin-right: 4px;
      text-decoration: none;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &-pages {
    display: flex;
  }

  &-ellipsis {
    line-height: 40px;
    pointer-events: none;
    text-align: center;
    user-select: none;
    width: 40px;
  }

  &-btn {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    line-height: 1.6rem;
    text-align: center;
    user-select: none;
    width: 40px;

    i {
      background-size: contain;
      filter: brightness(0) saturate(100%) invert(12%) sepia(91%) saturate(4407%) hue-rotate(238deg) brightness(85%) contrast(99%);
      height: 16px;
    }

    &--active {
      border: 2px solid $color-link-blue;
    }

    &--disabled {
      pointer-events: none;

      i {
        filter: brightness(0) saturate(100%) invert(12%) sepia(91%) saturate(4407%) hue-rotate(238deg) brightness(85%) contrast(99%) opacity(20%);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .pagination-btn:hover {
    background-color: $color-link-blue;
    color: $color-white;

    i {
      filter: brightness(0) saturate(100%) invert(100%);
    }
  }
}
