.error {
  &__container {
    margin: 20px auto 80px;
  }

  &__col {
    &-image {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .search {
        &__input {
          min-width: auto;
          width: 360px;
        }

        &__btn-submit {
          i {
            display: block;
          }
        }
      }
    }

    &-number {
      color: $color-blue;
      font-size: 160px;
      font-weight: 700;
      line-height: 200px;
      text-align: center;
    }
  }

  &__row {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__image {
    max-width: 550px;
    width: 100%;
  }

  &__search {
    &-message {
      margin: 40px 0 20px;
    }
  }

  &__help {
    margin-top: 40px;
  }
}

@include media-breakpoint-down(md) {
  .error {
    &__col {
      &-image,
      &-number {
        margin-bottom: 40px;
      }

      &-number {
        font-size: 120px;
        line-height: 140px;
      }

      &-info .search__btn-close {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .error {
    &__container {
      margin: 80px auto 120px;
    }

    &__col {
      &-info {
        order: 1;
      }

      &-image,
      &-number {
        order: 2;
      }
    }
  }
}
