.feature-mid-copy-block {
  overflow: hidden;

  &--right {
    & .row {
      flex-direction: row-reverse;
    }
  }

  &__image {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &-wrapper {
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
  }

  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    &-description {
      margin-bottom: 20px;
    }

    &-title {
      margin-bottom: 20px;
    }
  }

  & .cta-block {
    @extend .link;
  }
}

@include media-breakpoint-up(md) {
  .feature-mid-copy-block {
    &__image {
      &-wrapper {
        &--lg {
          margin-bottom: -160px;
        }
      }
    }

    &__content {
      &-badge {
        margin-bottom: 16px;
      }

      &-title {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .feature-mid-copy-block {
    &__image {
      &-wrapper {
        &--lg {
          margin-bottom: -80px;
        }
      }
    }

    &__content {
      padding-top: 20px;

      &-badge {
        margin-bottom: 20px;
      }

      &-title {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }
}
