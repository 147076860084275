// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (eot: "?", svg: "#" + str-replace($name, " ", "_"));

  $formats: (otf: "opentype", ttf: "truetype");

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin reset-button() {
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background: transparent;
  border: none;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  width: auto;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin transition() {
  transition: all 0.2s ease-out;
}

@mixin transition-04() {
  transition: all 0.4s ease-out;
}

@mixin disable-animation-v2() {
  &:hover {
    &::before {
      animation: none !important;
    }
  }
}

@mixin link-animation-v2() {
  &::before {
    animation: slidein 1s ease;
  }
}

/* stylelint-disable */
@keyframes slidein {
  0% { left: -10px; }
  1% { opacity: 1; }
  80% { opacity: 1; }
  100% { left: 100%; opacity: 0; }
}
/* stylelint-enable */

@mixin link-animation-v1() {
  &::after {
    background: linear-gradient(270deg, $color-white 0%, $color-link-blue 90%);
    width: 43%;
  }
}

@mixin link-animation-white() {
  &::after {
    background: linear-gradient(270deg, transparent, $color-white 90%);
    width: 43%;
  }
}

@mixin text--cut($numLines : 2) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  line-clamp: $numLines;
  overflow: hidden;
  text-overflow: ellipsis;
}
