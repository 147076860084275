//global styles
html,
body {
  color: $color-dark;
  font-family: $font-name-primary;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  min-width: 320px;
}

html {
  font-size: 10px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

a,
button {
  @include reset-button();

  &:focus,
  &:hover {
    cursor: pointer;
  }
}

a {
  font-size: 1.6rem;
}

a,
li[tabindex],
button[type="submit"] {
  outline-color: black;
  outline-offset: 7px;
  outline-width: 2px;

  &:focus {
    outline-color: black;
    outline-style: solid;
  }

  &.btn,
  &.pagination-btn {
    outline-offset: 0;
  }
}

input[type="checkbox"] {
  outline-offset: 0;
}

input,
button {
  outline-color: black;
  outline-offset: 0;

  &:focus {
    outline-color: black;
    outline-style: solid;
    outline-width: medium;
  }
}

.d-flex {
  display: flex;
}

.flex-w {
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.v-center {
  justify-content: center;
}

.v-left {
  justify-content: flex-start;
}

.v-right {
  justify-content: flex-end;
}

.align-self-center {
  align-self: center;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.defaultcase {
  text-transform: none !important;
}

.no-border {
  border: 0 none !important;
}

.bold {
  font-weight: 500;
}

.indent {
  &-10 {
    margin-bottom: 10px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-24 {
    margin-bottom: 24px;
  }

  &-40 {
    margin-bottom: 40px;
  }

  &-53 {
    margin-bottom: 53px;
  }
}

.svg {
  height: 0;
  position: absolute;
  width: 0;
}

.small,
.content-wrapper {
  margin: 40px 0;
}

.medium {
  margin: 80px 0;
}

.large {
  margin: 120px 0;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@include media-breakpoint-down(md) {
  .container {
    padding-left: $container-padding-m;
    padding-right: $container-padding-m;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;

    & > * {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .small,
  .content-wrapper {
    margin: 20px 0;
  }

  .medium {
    margin: 40px 0;
  }

  .large {
    margin: 80px 0;
  }
}
