.image-full-width {
  &__image {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }

  &__text {
    margin: 0 auto;
    max-width: 744px;
  }
}

@include media-breakpoint-down(md) {
  .image-full-width {
    &__text {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
