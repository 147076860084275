.insight-page .breadcrumbs__col {
  width: 100%;
}

.breadcrumbs {
  color: $color-grey;
  margin: 40px 0 20px;
  word-wrap: break-word;

  &__link {
    font-weight: 400;

    &::after {
      bottom: 4px;
      height: 1px;
    }
  }
}

@include media-breakpoint-down(md) {
  .breadcrumbs__col {
    @include text--cut;
  }
}

@include media-breakpoint-up(md) {
  .breadcrumbs {
    margin: 40px 0;
  }
}
