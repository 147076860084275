// =============================================================================
// Custom fonts
// =============================================================================
$font-name-primary: "Galano Grotesque";
$font-name-secondary: "Foundry Gridnik";

/*
TO DO Delete unusable fonts
 */

//Thin

/*
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 100;
  src: url(../../fonts/Galano-Grotesque-Thin.otf) format("opentype");
}

//Extra Light
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 200;
  src: url(../../fonts/Galano-Grotesque-ExtraLight.otf) format("opentype");
}

*/

//Light
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 300;
  src: url(../../fonts/Galano-Grotesque-Light.otf) format("opentype");
}

//Normal
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Galano-Grotesque.otf) format("opentype");
}

//Medium
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/Galano-Grotesque-Medium.otf) format("opentype");
}

//Semi Bold
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 600;
  src: url(../../fonts/Galano-Grotesque-SemiBold.otf) format("opentype");
}

//Bold
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Galano-Grotesque-Bold.otf) format("opentype");
}

/*
//Extra Bold
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 800;
  src: url(../../fonts/Galano-Grotesque-ExtraBold.otf) format("opentype");
}

//Heavy
@font-face {
  font-family: $font-name-primary;
  font-style: normal;
  font-weight: 900;
  src: url(../../fonts/Galano-Grotesque-Heavy.otf) format("opentype");
}

*/
//Italic

//Normal
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 400;
  src: url(../../fonts/Galano-Grotesque-Italic.otf) format("opentype");
}

/*
//Thin
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 100;
  src: url(../../fonts/Galano-Grotesque-Thin-Italic.otf) format("opentype");
}

//Extra Light
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 200;
  src: url(../../fonts/Galano-Grotesque-ExtraLight-Italic.otf) format("opentype");
}

//Light
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 300;
  src: url(../../fonts/Galano-Grotesque-Light-Italic.otf) format("opentype");
}

//Medium
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 500;
  src: url(../../fonts/Galano-Grotesque-Medium-Italic.otf) format("opentype");
}

//Semi Bold
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 600;
  src: url(../../fonts/Galano-Grotesque-SemiBold-Italic.otf) format("opentype");
}

//Bold
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 700;
  src: url(../../fonts/Galano-Grotesque-Bold-Italic.otf) format("opentype");
}

//Extra Bold
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 800;
  src: url(../../fonts/Galano-Grotesque-ExtraBold-Italic.otf) format("opentype");
}

//Heavy
@font-face {
  font-family: $font-name-primary;
  font-style: italic;
  font-weight: 900;
  src: url(../../fonts/Galano-Grotesque-Heavy-Italic.otf) format("opentype");
}
*/

//Regular
@font-face {
  font-family: $font-name-secondary;
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Foundry-Gridnik-Regular.otf) format("opentype");
}

//Bold
@font-face {
  font-family: $font-name-secondary;
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Foundry-Gridnik-Bold.otf) format("opentype");
}

.typography {
  a {
    color: $color-link-blue;

    &:hover {
      text-decoration: none;
    }
  }

  em {
    font-style: italic;
  }

  p {
    margin-bottom: 20px;
  }

  ul,
  ol {
    margin: 20px 0 20px 30px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li:not(:last-of-type) {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 20px 0;
  }
}

@include media-breakpoint-up(md) {
  .typography {
    ul,
    ol {
      margin: 40px 0 40px 30px;
    }
  }
}
