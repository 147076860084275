.footer {
  color: $color-white;
  font-weight: 300;

  &__container {
    &--image {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      position: relative;
      z-index: 2;
    }
  }

  &__nav {
    margin-bottom: 80px;

    &-title {
      font-weight: 400;
      line-height: 2rem;
      margin-bottom: 20px;
    }

    &-list {
      &-item:not(:last-of-type) {
        margin-bottom: 16px;
      }

      &-link {
        text-decoration: none;
      }
    }
  }

  &__socials {
    align-items: center;
    display: flex;

    &-title {
      font-weight: 400;
      margin-right: 40px;
    }

    &-list {
      display: flex;

      &-item {
        font-size: 2.4rem;

        &:not(:last-of-type) {
          margin-right: 1.6rem;
        }
      }

      & .cta-block {
        text-decoration: none;
      }
    }
  }

  &__logo {
    display: flex;

    &-image {
      display: block;
      max-width: 100px;
    }
  }

  &__bottom {
    overflow: hidden;
    position: relative;
  }

  &__bg-image {
    background-position: center top;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: -5%;
    top: 0;

    &--desktop {
      z-index: 0;
    }

    &--mobile {
      z-index: 1;
    }
  }

  &__slogan {
    display: flex;
    flex-direction: column;
    font-family: $font-name-secondary;
    text-transform: uppercase;

    & > b {
      font-weight: 600;
    }

    & > span {
      color: #cfd600;
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    background:
      linear-gradient(180deg, #252d4f 0%, rgba(37, 45, 79, 0) 100%),
      linear-gradient(90deg, #004631 0.02%, #61a83f 27.47%, #61a83f 40.06%, #41679f 69.71%, #283583 100%);
    padding: 80px 0 0;

    &__socials {
      margin-bottom: 120px;
    }

    &__logo {
      margin-bottom: 16px;
    }

    &__bottom {
      height: 635px;
      padding: 25px 0 40px;
    }

    &__bg-image--mobile {
      display: none;
    }

    &__slogan {
      font-size: 8rem;
      line-height: 6rem;
    }

    &__copyrights {
      align-self: flex-end;
      font-size: 1.4rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    background:
      linear-gradient(180deg, #252d4f 0%, rgba(37, 45, 79, 0) 100%),
      linear-gradient(180deg, #283583 0%, #41679f 57.29%, #61a83f 76.6%, #61a83f 87.3%, #004631 99.98%);
    padding: 20px 0 0;

    &__container {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__nav {
      &-column:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }

    &__socials {
      margin-bottom: 80px;
    }

    &__logo {
      margin-bottom: 34px;
    }

    &__bottom {
      height: 460px;
      padding: 6px 0 40px;
    }

    &__bg-image {
      bottom: 30px;
      top: 40px;
    }

    &__slogan {
      font-size: 4.8rem;
      line-height: 3.6rem;
    }

    &__copyrights {
      font-size: 1.2rem;
    }
  }
}
