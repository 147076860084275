.header {
  background-color: $color-white;
  position: sticky;
  top: 0;
  z-index: 10;

  &__container {
    align-items: center;
    display: flex;
    height: 57px;
  }

  &__right-panel {
    align-items: center;
    color: $color-link-blue;
    display: flex;
    margin-left: auto;
    padding: 13px 0 13px 0;
  }

  &__logo {
    &-wrap {
      padding: 13px 0 13px 12px;
    }

    &-img {
      width: 100px;
    }
  }

  & .search {
    &__bar {
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: -1;

      &--hidden {
        display: none;
      }

      &--none {
        display: none !important;
      }
    }

    &__btn-close {
      display: block;
      right: 12px;
    }
  }

  &__country-selector {
    color: $color-link-blue;
    font-weight: 500;

    a {
      text-decoration: none;
    }

    &-label {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 40px;
    }
  }

  &__get-in-touch,
  &__country-selector {
    display: none;
  }

  &__menu {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
  }

  i {
    color: $color-link-blue;
    cursor: pointer;
    font-size: 1.4rem;
  }

  .social-share {
    &__wrap {
      flex-direction: column;
    }
  }

  &__get-in-touch-container {
    flex-shrink: 0;
  }

  &__share {
    a {
      display: block;
    }
  }
}

.modal-social-share {
  &__modal {
    background-color: $color-white;
    box-shadow: 0 15px 20px 0 rgba(37, 45, 79, 0.5);
    display: none;
    left: 0;
    min-width: 360px;
    padding: 20px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 5;

    &--open {
      display: block;
    }

    .social-share {
      &__wrap {
        align-items: flex-start;
      }

      &__text {
        margin-bottom: 20px;
      }

      &__buttons {
        margin: 0;
      }

      &__elem {
        margin-bottom: 15px;
      }
    }

    .container {
      padding: 0;
    }
  }
}

.social-share {
  &__wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  i {
    color: $color-link-blue;
  }

  &__elem {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 24px;
    white-space: nowrap;
  }

  &__button {
    cursor: pointer;

    i {
      font-size: 2.4rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    color: $color-dark;
    margin-right: 24px;
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
  }

  &__copy {
    margin-right: 8px;
  }
}

@include media-breakpoint-down(md) {
  .header .search {
    &__btn-close {
      display: block;
      right: 0;
      top: -32px;
    }
  }
}

@include media-breakpoint-up(md) {
  .header {
    &__logo {
      &-wrap {
        margin-right: 33px;
        padding-left: 0;
      }
    }

    &__container {
      height: 80px;
    }

    &__get-in-touch,
    &__country-selector {
      display: block;
    }

    &__search,
    &__menu,
    &__get-in-touch {
      margin-left: 12px;
    }

    &__search {
      &-link {
        display: block;
      }
    }

    &__menu {
      display: none;
    }

    .social-share {
      &__wrap {
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    .header__logo-wrap {
      @media only screen and (min-width: 1200px) {
        padding-left: 0;
      }
    }
  }

  .modal-social-share {
    position: relative;

    &__modal {
      left: 50%;
      top: 118px;
      transform: translate(-50%, -50%);
      width: auto;
    }
  }

  .social-share {
    &__wrap {
      align-items: center;
      justify-content: center;
    }

    &__buttons {
      flex-wrap: nowrap;
    }
  }
}
