.speakers-block {
  & h4 {
    margin-bottom: 40px;
  }

  &__impact {
    margin-bottom: 40px;
  }

  & .page-details__row {
    margin-top: 0;
  }

  & .person-block {
    margin-bottom: 80px;

    & .page-details__row {
      & .page-details__author {
        &-links {
          color: $color-link-blue;
          display: flex;
          font-size: 1.8rem;
        }
      }
    }

    .page-details__col {
      flex-basis: auto;
    }
  }

  &__row {
    margin-bottom: -80px;
  }

  &--horizontal {
    & .person-block {
      & .page-details__row {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    & .page-details__author {
      &-bio {
        margin-top: 20px;
      }

      &-image {
        margin-bottom: 40px;
      }

      &-links {
        flex-wrap: wrap;
        margin-top: 15px;
        position: static;
      }
    }

    .page-details__col {
      span {
        white-space: initial;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .speakers-block {
    & h4 {
      margin-bottom: 20px;
    }

    &__impact {
      margin-bottom: 80px;
    }

    & .person-block {
      margin-bottom: 40px;
    }

    &__row {
      margin-bottom: -40px;
    }
  }
}
