.filter {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  &__apply-d {
    display: none;
  }

  &__apply-d,
  &__apply-m {
    background: white;
    bottom: 0;
    left: 0;
    padding: 15px 0 20px;
    position: absolute;
    width: 95%;

    span {
      pointer-events: none;
    }
  }

  &__label {
    display: flex;

    &-text {
      flex: 1;
    }
  }

  &__title {
    display: block;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__checkbox {
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__count {
    margin-left: auto;
  }

  &__apply-m {
    margin-top: 20px;
    text-align: center;
  }
}

@include media-breakpoint-up(md) {
  .filter {
    &__apply-d {
      display: block;
      text-align: center;
    }

    &__apply-m,
    &__title {
      display: none;
    }
  }
}
