.feature-interactive-block {
  background-color: $color-white;
  position: relative;
  z-index: 1;

  &__image {
    display: block;
    max-width: 838px;
    width: 100%;
  }

  &__bullet-title {
    display: none;
  }

  &__title {
    margin-bottom: 22px;
  }

  &__text {
    background-color: $color-white;
    margin: -20px 10px 0 10px;
    padding: 20px 20px 30px 25px;
    position: relative;
  }
}

@include media-breakpoint-up(md) {
  .feature-interactive-block {
    &__title {
      margin-bottom: 30px;
    }

    &__wrap {
      position: relative;
    }

    &__image {
      &-wrap {
        margin-left: 288px;
      }
    }

    &__text {
      margin-left: 456px;
      margin-top: 0;
      padding: 25px 0 0 24px;
    }

    &__bullet-title {
      display: flex;
      height: 100%;
      width: 100%;

      a {
        align-items: center;
        display: flex;
        flex-grow: 1;
        height: 100%;
        text-decoration: navajowhite;
      }

      span {
        align-items: center;
        cursor: default;
        display: flex;
        flex-grow: 1;
        height: 100%;
        text-decoration: navajowhite;
      }
    }

    .tns-nav {
      background-color: $color-white;
      border: 2px solid $color-grey-light;
      display: flex;
      flex-direction: column;
      left: 0;
      padding: 34px 32px 60px 37px;
      position: absolute;
      top: 85px;
      width: 456px;

      button {
        align-items: center;
        background-color: $color-white;
        border-radius: 0;
        color: $color-link-blue;
        display: flex;
        font-weight: 500;
        margin: 0;
        min-height: 48px;
        width: 100%;

        &.tns-nav-active {
          background-color: $color-link-blue;
          color: $color-white;
          padding-left: 22px;
        }
      }
    }
  }
}
