.feature-image-text-heading-cta-block {
  overflow: hidden;

  &__image {
    display: block;
    width: 100%;
  }

  &__content {
    & :last-child {
      margin-bottom: 0;
    }

    &-badge,
    &-title,
    &-description {
      margin-bottom: 20px;
    }
  }

  & .cta-block {
    @extend .link;
  }
}

@include media-breakpoint-up(md) {
  .feature-image-text-heading-cta-block {
    &__content {
      &-description {
        margin-bottom: 40px;
      }
    }
  }
}
