.country-selector {
  display: none;
  margin-bottom: auto;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    padding-left: 24px;
  }

  &__close {
    display: none;
    font-size: 12px;

    i {
      color: $color-dark;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__country {
    &-link {
      align-items: center;
      color: $color-dark;
      display: flex;
      padding: 14px 24px;
    }

    &-flag {
      height: 20px;
      margin-right: 24px;
      width: 28px;
    }

    & .icon-check {
      color: $color-dark;
      display: none;
      font-size: 12px;
      margin-left: auto;
    }

    &--selected {
      .country-selector__country-name {
        font-weight: 500;
      }

      & .icon-check {
        display: block;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__confirm {
    padding: 12px 24px;
  }

  &__modal {
    align-items: center;
    background-color: $color-modal-bg;
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 30;

    &--open {
      display: flex;
    }

    &-dialog {
      background-color: $color-white;
      max-width: 360px;
      padding: 32px 20px 40px;
      width: 33%;

      .country-selector {
        &__country {
          &-link {
            padding: 14px 20px;
          }

          &:hover {
            background-color: $color-grey-light;
            cursor: pointer;
          }
        }

        &__header {
          margin-bottom: 20px;
          padding-left: 20px;
        }

        &__close {
          display: block;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .country-selector {
    &--open {
      margin-bottom: auto;
      margin-top: 18px;
    }

    &__footer {
      display: none;
    }

    &__modal {
      display: none;
    }
  }
}
