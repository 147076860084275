.sorting-select {
  outline-offset: 5px;
  position: relative;
  white-space: nowrap;
  width: 100%;

  &__label {
    align-items: center;
    color: $color-dark;
    cursor: pointer;
    display: flex;
    font-style: normal;
    font-weight: normal;
    justify-content: center;
    padding-right: 25px;
    position: relative;
  }

  &__dots {
    display: none;
  }

  &__selected-label {
    display: none;
  }

  &__icon {
    position: absolute;
    right: 1px;
  }

  &__list {
    background-color: $color-white;
    box-shadow: 0 10px 20px 0 rgba(37, 45, 79, 0.5);
    min-width: 191px;
    padding: 8px 0;
    position: absolute;
    right: -12px;
    text-align: left;
    top: 42px;
    z-index: 2;

    & li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      outline-offset: 0;
      padding: 10px 20px;

      &[data-selected] {
        .icon-check {
          display: block;
        }
      }

      .icon-check {
        font-size: 13px;
      }
    }
  }

  &--active {
    .sorting-select__icon {
      transform: rotate(270deg);
    }
  }

  .icon-check {
    color: $color-link-blue;
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .sorting-select {
    &__label {
      justify-content: left;
      padding-left: 20px;
      text-align: left;
    }

    &__dots {
      display: inline;
    }

    &__selected-label {
      display: inline;
      margin-left: 24px;
    }

    &__list {
      left: auto;
      text-align: left;

      & li {
        border-top: none;

        &:hover {
          background-color: $color-grey-light;
        }
      }
    }

    &--active {
      .sorting-select__label {
        background-color: $color-white;
        border-bottom: none;
        color: $color-dark;
      }

      .sorting-select__icon {
        color: $color-dark;
      }
    }
  }
}
